import React from 'react';
import Col from 'react-bootstrap/Col';
import './Esposizioni.scss';
import { useLanguage } from '../../../LanguageContext';

const Esposizioni = ({ currentArtwork, isArrayEmpty }) => {
  const {selectedLanguage} = useLanguage();
  return (
    !isArrayEmpty(currentArtwork.exhibition_history_ids) && (
      <Col className="col-12 col-md-12" style={{ textAlign: 'justify' }}>
        <label>
          <strong>{selectedLanguage === 'it' ? 'Esposizioni' : 'Exhibitions'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
        {currentArtwork.exhibition_history_ids
          .sort((a, b) => a.year - b.year)
          .map((exhibition, index) => (
            <div key={index}>
              <p>
                <span>{exhibition.short_title}</span>
                <br />
                {exhibition.long_title}
                <br />
              </p>
            </div>
          ))}
          </Col>
      </Col>
    )
  );
};

export default Esposizioni;
