import React from 'react';
import Col from 'react-bootstrap/Col';
import './NoteAggiuntive.scss';
import { useLanguage } from '../../../LanguageContext';

const NoteAggiuntive = ({ currentArtwork, isArrayEmpty }) => {
  const { selectedLanguage } = useLanguage();

  const getLangValue = (data) => {
    return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  };

  const getDescription = () => {
    let description = '';
    if (!isArrayEmpty && currentArtwork.additional_information) {
      description = getLangValue(currentArtwork.additional_information);
      return description.replace(/\n/g, '<br>');
    } else {
      return false;
    }
  };

  const description = getDescription();

  return (
    !isArrayEmpty && description && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Note aggiuntive' : 'Additional notes'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxDesc">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Col>
      </Col>
    )
  );
};

export default NoteAggiuntive;
