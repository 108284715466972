import React from 'react';
import { useLanguage } from '../../../LanguageContext';
import Col from 'react-bootstrap/Col';
import './Bibliography.scss';

const Bibliography = ({ currentArtwork, isArrayEmpty, isShort }) => {
  const { selectedLanguage, handleLanguageChange } = useLanguage();

  return (
    !isArrayEmpty(currentArtwork.bibliography_ids) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Bibliografia' : 'Bibliography'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxBiblio boxField">
        {isShort
          ? currentArtwork.bibliography_ids
              .filter((biblio) => biblio.is_short)
              .sort((a, b) => a.anno - b.anno)
              .map((biblio, index) => (
                <p key={index}>
                  <span className='recordsBib'>
                    {biblio.nome} {biblio.link && <a href={biblio.link} target="_blank" rel="noopener noreferrer">Link</a>}
                  </span>
                </p>
              ))
          : currentArtwork.bibliography_ids
              .sort((a, b) => a.anno - b.anno)
              .map((biblio, index) => (
                <p key={index}>
                  <span className='recordsBib'>
                    {biblio.nome} {biblio.link && <a href={biblio.link} target="_blank" rel="noopener noreferrer">Link</a>}
                  </span>
                </p>
              ))}
        </Col>
      </Col>
    )
  );
};

export default Bibliography;
