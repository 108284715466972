import React from 'react';
import './DimensionLabel.scss';
import depthIcon from './img/depth.png';
import widthIcon from './img/width.png';
import heightIcon from './img/height.png';
import diameterIcon from './img/diameter.png';
import thicknessIcon from './img/thickness.png';

const DimensionLabel = ({ label, value }) => {
  let icon = null;

  switch (label) {
    case 'Profondità':
      icon = depthIcon;
      break;
    case 'Larghezza':
      icon = widthIcon;
      break;
    case 'Altezza':
      icon = heightIcon;
      break;
    case 'Diametro':
      icon = diameterIcon;
      break;
    case 'Spessore':
      icon = thicknessIcon;
      break;
    default:
      icon = null;
  }

  return (
    <span className="dimSt">
      {icon && <img src={icon} alt={label} className="icon" />}
      <i>{label}</i>: {value} mm
    </span>
  );
};

export default DimensionLabel;
