import React from 'react';
import Col from 'react-bootstrap/Col';
import './Datazione.scss';
import { useLanguage } from '../../../LanguageContext';

const ArtworkDates = ({ currentArtwork, isArrayEmpty }) => {
  // Helper function to get language-specific value
  // console.log(currentArtwork);
  const {selectedLanguage} = useLanguage();
  const getLangValue = (data) => {
    return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  };

  return (
    !isArrayEmpty(currentArtwork.dates) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Datazione' : 'Dates'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
          {currentArtwork.dates
            .sort((a, b) => b.date_int - a.date_int)
            .map((date, index) => (
              <p key={index}>
                {getLangValue(date.date)}
                {date.date_note && ` ${getLangValue(date.date_note)}`}
                {index < currentArtwork.dates.length - 1 ? '; ' : ''}
              </p>
            ))}
        </Col>
      </Col>
    )
  );
};

export default ArtworkDates;
