import axios from "axios";

const initSession = async () => {
  try {
    const data = JSON.stringify({
      "jsonrpc": "2.0",
      "params": {
        "db": process.env.REACT_APP_ODOO_DB,
        "login": process.env.REACT_APP_ODOO_LOGIN,
        "password": process.env.REACT_APP_ODOO_PASSWORD
      }
    });

    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_ODOO_BASEURL}/web/session/authenticate`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };

    const response = await axios(config);
    return response;
  } catch (error) {
    throw new Error("Error initializing session");
  }
};

const getSessionId = () => {
  return localStorage.getItem("session_id");
};

const getAll = async (page, pageSize, name, data_inizio, data_fine, selectedTag, selectedSubjectTag, luogo_conservazione, selectedLanguage, freeSearch) => {
  const session_id = getSessionId();

  try {
    const data = JSON.stringify({
      "jsonrpc": "2.0",
      "params": {
        "data": {
          'page': page,
          'page_size': pageSize,
          'name': name,
          'data_inizio': data_inizio,
          'data_fine': data_fine,
          'selectedTag': selectedTag,
          'selectedSubjectTag': selectedSubjectTag,
          'luogo_conservazione': luogo_conservazione,
          'language': selectedLanguage,
          'freeSearch': freeSearch,
        }
      }
    });

    const config = {
      method: 'POST',
      url: '/api/artwork/getAll',
      headers: {
        'Content-Type': 'application/json',
        "X-Openerp-Session-Id": session_id,
      },
      data: data
    };

    const response = await axios(config);
    
    if (response && response.data && response.data.result) {
      // console.log(response);
      return {
        artworks: response.data.result.response,
        totalArtworksCount: response.data.result.totalArtworksCount,
      };
    } else {
      throw new Error("Unexpected response structure");
      // console.log(response);
    }
  } catch (error) {
    throw new Error("Failed to get all artworks: " + error.message);
  }
};

const get = async (id) => {
  const session_id = getSessionId();

  try {
    const data = JSON.stringify({
      "jsonrpc": "2.0",
      "method": "call",
      "params": {}
    });

    const config = {
      method: 'POST',
      url: `/api/artwork/get/${id}`, // Adjusted to use only the id
      headers: {
        'Content-Type': 'application/json',
        "X-Openerp-Session-Id": session_id,
      },
      data: data
    };

    // console.log(config);

    const response = await axios(config);

    if (response && response.data && response.data.result) {
      return response.data.result;
    } else {
      throw new Error("Unexpected response structure");
    }
  } catch (error) {
    console.error(error); // More detailed error logging
    throw new Error("Error getting artwork by ID");
  }
};

const getTags = async () => {
  const session_id = getSessionId();

  try {
    const data = JSON.stringify({
      "jsonrpc": "2.0",
      "method": "call",
      "params": {}
    });

    const config = {
      method: 'POST',
      url: '/api/artwork/getTags',
      headers: {
        'Content-Type': 'application/json',
        "X-Openerp-Session-Id": session_id,
      },
      data: data
    };

    // console.log(config);

    const response = await axios(config);

    if (response && response.data && response.data.result) {
      // console.log(response.data.result);
      return response.data.result;
    } else {
      throw new Error("Unexpected response structure", response.data);
    }
  } catch (error) {
    throw new Error("Error getting artwork tag", error.message);
  }
}


const getSubjectTags = async () => {
  const session_id = getSessionId();

  try {
    const data = JSON.stringify({
      "jsonrpc": "2.0",
      "method": "call",
      "params": {}
    });

    const config = {
      method: 'POST',
      url: '/api/artwork/getSubjectTags',
      headers: {
        'Content-Type': 'application/json',
        "X-Openerp-Session-Id": session_id,
      },
      data: data
    };

    const response = await axios(config);

    if (response && response.data && response.data.result) {
      // console.log(response.data.result);
      return response.data.result;
    } else {
      throw new Error("Unexpected response structure", response.data);
    }
  } catch (error) {
    throw new Error("Error getting artwork tag", error.message);
  }
}

const artworkService = {
  getAll,
  get,
  getTags,
  getSubjectTags,
  initSession,
};

export default artworkService;
