// NameFilter.js
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLanguage } from '../../LanguageContext';

const NameFilter = ({ searchName, onChangeSearchName, retrieveArtworks }) => {
  const { selectedLanguage } = useLanguage();
  return (

        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={selectedLanguage === 'it' ? 'Cerca per opera' : 'Search by artwork'}
            value={searchName}
            onChange={onChangeSearchName}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => retrieveArtworks()}
            >
              {selectedLanguage === 'it' ? 'Cerca' : 'Search'}
            </button>
          </div>
        </div>

  );
};

export default NameFilter;
