import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import ImageDecoder from "../../Utils/ImageDecoder/ImageDecoder";
import "./OpereCorrelate.scss";
import { useLanguage } from "../../../LanguageContext";

export const OpereCorrelate = ({ decodeBase64Image, isArrayEmpty, currentArtwork }) => {
  const {selectedLanguage} = useLanguage();
  return (
    !isArrayEmpty(currentArtwork.opere_correlate_ids) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Opere correlate' : 'Related Artworks'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxArt text-center">
        {currentArtwork.opere_correlate_ids.map((artwork, index) => (
          <Col key={index}>
            <a className="titleCorr" href={`/artwork/${artwork.id}`} key={artwork.id}>
                <span>
                    <div style={{fontSize:'12px', fontStyle: 'italic'}}>
                        {`${artwork.location.city_town}, ${artwork.location.name_off}`}
                    </div>
                    <div style={{fontWeight:'400'}}>
                        {artwork.name[selectedLanguage === 'it' ? 'it_IT' : 'en_GB']}
                    </div>
                </span>
            </a>
            {artwork.pictures
              .filter(picture => picture.is_cover === true && picture.image)
              .map(picture => (
                  <ImageDecoder
                        key={picture.id}
                        className='img-fluid imgCorr'
                        base64Image={picture.image}
                        alt={picture.title}
                        isGallery={false}
                        isCorrelate={true}
                    />
              ))}
          </Col>
        ))}
        </Col>

      </Col>
    )
  );
};

export default OpereCorrelate;