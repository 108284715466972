import React from 'react';
import Col from 'react-bootstrap/Col';
import './Author.scss';
import { useLanguage } from '../../../LanguageContext';

const ArtworkAuthors = ({ currentArtwork, isArrayEmpty }) => {
  const { selectedLanguage, handleLanguageChange } = useLanguage();
  const getLangContent = (content) => content ? content[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';

  // Funzione per determinare il titolo in base al numero di autori
  const getLabel = () => {
    const count = currentArtwork.authors_of_cards.length;
    if (selectedLanguage === 'it') {
      return count > 1 ? 'Autori della scheda' : 'Autore della scheda';
    } else {
      return count > 1 ? 'Entry authors' : 'Entry author';
    }
  };

  return (
    !isArrayEmpty(currentArtwork.authors_of_cards) && (
      <Col className="col-12 col-md-12" style={{textAlign:"right"}}>
        <label>
          <strong>{getLabel()}</strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
          {currentArtwork.authors_of_cards.map((author, index) => (
            <span key={index}>
              <span style={{fontStyle:"italic"}}>{author.name} {author.surname}</span> {getLangContent(author.note)}
              {index < currentArtwork.authors_of_cards.length - 1 ? '; ' : ''}
            </span>
          ))}
        </Col>
      </Col>
    )
  );
};

export default ArtworkAuthors;
