import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './homepage.scss';
import Carousel from '../../components/Carousel/Carousel';
import { useLanguage } from '../../LanguageContext';
import DisclaimerRessearch from '../../components/Featured/DisclaimerResearch';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { Button } from 'react-bootstrap';

function HomePage() {
  const { selectedLanguage } = useLanguage();

  return (
    <>
    <Helmet>
      {/* Titolo */}
      <title>Bisanzio Svelata</title>

      {/* Meta Tags */}
      <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="Bisanzio Svelata" />
      <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="og:url" content="https://www.bisanziosvelata.it/" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta property="twitter:title" content="Bisanzio Svelata" />
      <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="twitter:url" content="https://www.bisanziosvelata.it/" />

      {/* URL Canonico */}
      <link rel="canonical" href="https://www.bisanziosvelata.it/" />
    </Helmet>
    
    <Container fluid className="bg-container">
      <Row className="justify-content-center align-items-center homeRow">      
          <Col className='col-11 col-md-6'>
          <h4 className="projectBriefDesc" style={{ textAlign: 'justify' }}>
          {selectedLanguage === 'it'
            ?
            <>
              {/* <br></br>
              <br></br> */}
              Benvenuti in <span className='highlightS'>Bisanzio Svelata</span>, il sito web ufficiale del progetto PRIN 2017T3ZC3 2017 <span className='highlightS'>Navigare nell’Italia bizantina. Un catalogo online per la conoscenza e la valorizzazione di un patrimonio artistico sommerso.</span>
              <br></br>
              <br></br>
              Il nostro obiettivo è quello di offrire il <a style={{color:"#876a35", fontWeight:"400"}} href='/research'>primo database digitale</a> delle opere d'arte mobili bizantine esposte nelle chiese, nei musei e nelle collezioni pubbliche e private in Italia. Grazie alle schede di approfondimento e alla mappatura dettagliata degli oggetti, <span className='highlightS'>Bisanzio Svelata</span> vuole diffondere la conoscenza di un patrimonio artistico ricchissimo, disseminato su tutto il territorio italiano e ancora poco conosciuto nella sua interezza. Questo innovativo museo virtuale aiuterà inoltre a ricostruire i complessi fenomeni di scambio e circolazione artistica tra Bisanzio e l'Italia, esplorando le dinamiche della storia religiosa, della committenza, della diplomazia, del commercio e del collezionismo.
              <br></br>
            </>

            :

            <>
            <br></br>
            <br></br>
              Welcome to <span className='highlightS'>Bisanzio Svelata</span>, the official website of the PRIN 2017T3ZC3 2017 project <span className='highlightS'>Navigating through Byzantine Italy. An Online Catalog to Study and Enhance a Submerged Artistic Heritage.</span>
              <br></br>
              <br></br>
              Our goal is to provide the first <a style={{color:"#876a35", fontWeight:"400"}} href='/research'>digital database</a> of Byzantine portable objects exhibited in churches, museums, and public and private collections in Italy. Thanks to detailed records and object mapping, <span className='highlightS'>Bisanzio Svelata</span> aims to spread knowledge of a rich artistic heritage that is scattered throughout Italy and still relatively unknown in its entirety. This innovative virtual museum will also help reconstruct the complex phenomena of artistic exchange and circulation between Byzantium and Italy by exploring the dynamics of religious history, patronage, diplomacy, trade, and collecting.
              <br></br>
            </>
          }
         
        </h4> 
            
          </Col>
          <Col className='col-11 col-md-5' style={{alignSelf: 'center'}}>
            <Carousel />
          </Col>
      </Row>
      <Row className='justify-content-center align-items-center'>
          <Button href='/research' className='btn btn-lg btn-block' style={{
            marginTop: '20px', marginBottom: '20px', background: '#7f163d', borderColor: 'unset', width: '120px', color: 'white'}}>
            {selectedLanguage === 'it' ? 'Naviga' : 'Explore'}
          </Button>
          <br></br>
          <br></br>
      </Row>
    </Container>
    <Footer />
    
    </>
  );
}

export default HomePage;
