// ArtworkList.js
import React from 'react';
import ArtworkListItem from './ArtworkListItem';
import Col from 'react-bootstrap/Col';
import './artwork.scss';
import Loading from '../Loading/Loading';


const ArtworkList = ({ artworks, currentIndex, setActiveArtwork, loading }) => {
  
  return (
    <Col className="text-left custumListCont">
    {loading ? 
      <Loading />
     : 
      <ul className="list-group">
        {artworks
          .map((artwork, index) => 
            artwork && (
              <ArtworkListItem
                key={index}
                artwork={artwork}
                isActive={currentIndex === index}
                handleClick={() => setActiveArtwork(artwork, index)}
              />
            )
          )
          }
      </ul>
      }
    </Col>
  );
  
};

export default ArtworkList;
