import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../LanguageContext';

const SubjectFilter = ({ subjectTags, selectedSubjectTag, retrieveArtworks, onChangeSelectedSubjectTag }) => {
    const { selectedLanguage } = useLanguage();
    const [searchQuery, setSearchQuery] = useState(selectedSubjectTag || '');
    const [showOptions, setShowOptions] = useState(false);
    const [selectedOptionIndex, setSelectedOptionIndex] = useState(-1);

    useEffect(() => {
        setSearchQuery(selectedSubjectTag || '');
    }, [selectedSubjectTag]);

    const onSearchInputChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        setShowOptions(true);
        setSelectedOptionIndex(-1);
    };

    const onKeyDown = (e) => {
        if (e.key === 'Enter') {
            if (selectedOptionIndex >= 0 && selectedOptionIndex < filteredTags.length) {
                const selectedTag = filteredTags[selectedOptionIndex];
                onChangeSelectedSubjectTag({ target: { value: selectedTag.name } });
                setSearchQuery(selectedTag.name);
                setShowOptions(false);
            }
        } else if (e.key === 'ArrowUp') {
            e.preventDefault();
            setSelectedOptionIndex(prevIndex => (prevIndex - 1 + filteredTags.length) % filteredTags.length);
        } else if (e.key === 'ArrowDown') {
            e.preventDefault();
            setSelectedOptionIndex(prevIndex => (prevIndex + 1) % filteredTags.length);
        }
    };

    // Filtrare i tag in base alla query di ricerca
    const filteredTags = subjectTags.filter(tag =>
        tag.name.toLowerCase().includes(searchQuery.toLowerCase())
    ).slice(0, 5);

    const getTagValue = (index) => {
        if (filteredTags && filteredTags[index]) {
            return filteredTags[index].name;
        }
        return '';
    };
    
    const onClickInput = (index) => {
        const tagName = getTagValue(index);
        if (tagName) {
            onChangeSelectedSubjectTag({ target: { value: tagName } });
            setSearchQuery(tagName);
            setShowOptions(false);
        }
    };
    

    return (
        <div className="input-group mb-3">
            <input
                type="text"
                className="form-control"
                placeholder={selectedLanguage === 'it' ? 'Cerca oggetto...' : 'Search object...'}
                value={searchQuery}
                onChange={onSearchInputChange}
                onKeyDown={onKeyDown}
                onBlur={() => setShowOptions(false)}
                onCompositionStart={() => setShowOptions(true)}
            />

            {/* Suggestions */}
            {showOptions && filteredTags.length > 0 && (
                <div className="dropdown-menu" style={{ display: 'block' }}>
                    {filteredTags.map((tag, index) => (
                        <button
                            key={index}
                            className={`dropdown-item ${selectedOptionIndex === index ? 'active' : ''}`}
                            onMouseDown={() => onClickInput(index)}
                        >
                            {tag.name}
                        </button>
                    
                    ))}
                </div>
            )}

            <div className="input-group-append">
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => retrieveArtworks()}
                >
                    {selectedLanguage === 'it' ? 'Cerca' : 'Search'}
                </button>
            </div>
        </div>
    );
};

export default SubjectFilter;
