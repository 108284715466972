import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './Inscriptions.scss';
import { useLanguage } from '../../../LanguageContext';

const Inscriptions = ({ currentArtwork, isArrayEmpty }) => {
  const {selectedLanguage} = useLanguage();
  const getLangValue = (data) => {
    return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  };

  return (
    !isArrayEmpty(currentArtwork.inscription_ids) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Dati epigrafici' : 'Inscriptions'}</strong>
        </label>
        <Col className='boxInsc'>
            {currentArtwork.inscription_ids.map((inscription, index) => (
                <Row className='boxRow'>
                    <Col className="col-md-3 leftBB">
                        {/* <p>
                            <i>{selectedLanguage === 'it' ? 'Lato: ' : 'Side: '}</i>
                            {inscription.lato_ids.map((lato, idx) => (
                            <span key={idx}> {getLangValue(lato.name)}</span>
                            ))}
                        </p> */}
                        <p><i>{selectedLanguage === 'it' ? 'Lato: ' : 'Side: '}</i>{inscription.fonte}</p>
                    </Col>
                    <Col className="col-md-9 rightBB">
                    <i>{selectedLanguage === 'it' ? 'Contenuto: ' : 'Content: '} </i><p style={{ whiteSpace: 'pre-line' }}>{inscription.contenuto}</p>
                    </Col>
                </Row>
            ))}
         </Col>
      </Col>
    )
  );
};

export default Inscriptions;
