import React from 'react';
import Col from 'react-bootstrap/Col';
import { useLanguage } from '../../LanguageContext';
import { FiCopy } from 'react-icons/fi'; // Importa l'icona di copia da react-icons
import './CiteArt.css';

const CiteArt = ({ currentArtwork }) => {
    const { selectedLanguage } = useLanguage();

    const getLangValue = (data) => {
        return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
    };

    // Funzione per generare il testo della citazione come stringa
    const generateCitationText = () => {
        const authorText = currentArtwork.authors_of_cards.map(author => `${author.name} ${author.surname}`).join('; ');
        const artworkTitle = getLangValue(currentArtwork.name);
        return `${authorText}, "${artworkTitle}", in Navigare nell’Italia bizantina. Un catalogo online per la conoscenza e la valorizzazione di un patrimonio artistico sommerso. - www.bisanziosvelata.it`;
    };

    // Funzione per copiare il testo negli appunti
    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            alert(selectedLanguage === 'it' ? 'Testo copiato negli appunti - www.bisanziosvelata.it' : 'Text copied to clipboard - www.bisanziosvelata.it');
        }).catch(err => {
            console.error('Errore durante la copia: ', err);
        });
    };

    // Prepara il testo della citazione da copiare
    const citationText = generateCitationText();

    return (
        <Col className="col-12 col-md-12">
            <label>
                <strong>{selectedLanguage === 'it' ? 'Cita questa pagina' : 'Cite this page'}</strong>
                <FiCopy style={{ marginLeft: '10px', cursor: 'pointer' }} onClick={() => copyToClipboard(citationText)} />
            </label>
            <Col className="col-12 col-md-12 boxDesc">
                {/* Visualizza il testo formattato della citazione */}
                <p dangerouslySetInnerHTML={{ __html: citationText.replace(/- www.bisanziosvelata.it/, '<i>- www.bisanziosvelata.it</i>') }} />
            </Col>
        </Col>
    );
};

export default CiteArt;
