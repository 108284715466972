import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../LanguageContext';

import ArtworkDataService from '../../services/ArtworkService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FilterMain from '../../components/Filters/FiltersMain';

import ArtworkList from '../../components/Artwork/ArtworkList';
import ArtworkDetails from '../../components/Artwork/ArtworkDetails';
import PaginationComponent from '../../components/Artwork/PaginationComponent';
import './Artworklistpage.scss';

const ArtworksList = () => {
  const [artworks, setArtworks] = useState([]);
  const [currentArtwork, setCurrentArtwork] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState('');
  
  const [searchData_inizio, setSearchData_inizio] = useState('');
  const [searchData_fine, setSearchData_fine] = useState('');

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(15);
  const [totalArtworksCount, setTotalArtworksCount] = useState(0);
  const [loading, setLoading] = useState(false);

  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');

  const [subjectTags, setSubjectTags] = useState([]);
  const [selectedSubjectTag, setSelectedSubjectTag] = useState('');

  const [search_luogo_conservazione, setSearch_luogo_conservazione] = useState('');
  const [freeSearch, setFreeSearch] = useState('');

  const { selectedLanguage } = useLanguage();

  const onChangeSelectedTag = (e) => {
    const selectedTag = e.target.value;
    setSelectedTag(selectedTag);
  };

  const onChangeSelectedSubjectTag = (e) => {
    const selectedSubjectTag = e.target.value;
    setSelectedSubjectTag(selectedSubjectTag);
  };

  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const onChangeLuogoConservazione = (e) => {
    const luogo_conservazione = e.target.value;
    setSearch_luogo_conservazione(luogo_conservazione);
  };

  const onChangeSearchDataInizio = (e) => {
    const searchData_inizio = e.target.value;
    setSearchData_inizio(searchData_inizio);
  };

  const onChangeSearchDataFine = (e) => {
    const searchData_fine = e.target.value;
    setSearchData_fine(searchData_fine);
  };

  const onChangeFreeSearch = (e) => {
    const searchFree = e.target.value;
    setFreeSearch(searchFree);
  };

  const retrieveTags = () => {
    ArtworkDataService.getTags()
      .then((response) => {
        // console.log(response.response)
        setTags(response.response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const retrieveSubjectTags = () => {
    ArtworkDataService.getSubjectTags()
      .then((response) => {
        // console.log(response.response)
        setSubjectTags(response.response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const retrieveArtworks = (page) => {
    setLoading(true);
    setCurrentPage(page);
    ArtworkDataService.getAll(page, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch)
      .then((response) => {
        setArtworks(response.artworks);
        setTotalArtworksCount(response.totalArtworksCount);
        // console.log(response.artworks);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (page, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch) => {
    retrieveArtworks(page, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch);
  };

  useEffect(() => {
    retrieveTags();
    retrieveSubjectTags();
  }, []);

  useEffect(() => {
    retrieveArtworks(currentPage, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch);
  }, [currentPage, pageSize]);

  const setActiveArtwork = (artwork, index) => {
    setCurrentArtwork(artwork);
    setCurrentIndex(index);
  };

  return (
    <Container className='bg-container'>
      <br />
      <FilterMain
        searchName={searchName}
        onChangeSearchName={onChangeSearchName}
        searchData_fine={searchData_fine}
        searchData_inizio={searchData_inizio}
        onChangeSearchDataInizio={onChangeSearchDataInizio}
        onChangeSearchDataFine={onChangeSearchDataFine}
        retrieveArtworks={retrieveArtworks}
        tags={tags}
        subjectTags={subjectTags}
        selectedTag={selectedTag}
        selectedSubjectTag={selectedSubjectTag}
        onChangeSelectedTag={onChangeSelectedTag}
        onChangeSelectedSubjectTag={onChangeSelectedSubjectTag}
        search_luogo_conservazione={search_luogo_conservazione}
        onChangeLuogoConservazione={onChangeLuogoConservazione}
        freeSearch={freeSearch}
        onChangeFreeSearch={onChangeFreeSearch}
      />
      <br />

      <Row>
        <Col md={4} className="text-left mb-2">
            <ArtworkList
              artworks={artworks}
              currentIndex={currentIndex}
              setCurrentArtwork={setCurrentArtwork}
              setActiveArtwork={setActiveArtwork}
              setCurrentIndex={setCurrentIndex}
              loading={loading}
            />
            {totalArtworksCount > pageSize && (
              <PaginationComponent
                currentPage={currentPage}
                pageSize={pageSize}
                totalArtworksCount={totalArtworksCount}
                handlePageChange={handlePageChange}
              />
            )}
        </Col>
        <Col md={8} className="text-left boxDetails" >
          {currentArtwork ? (
            <ArtworkDetails currentArtwork={currentArtwork} />
          ) : (
            <div>
              <br />
              <p>{selectedLanguage === 'it' ? "Selezionare un'opera" : "Please click on an Artwork..."}</p>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ArtworksList;
