import React, { useState } from 'react';
import './ImageDecoder.scss'; 

const ImageDecoder = ({ base64Image, altText, className, isCover, isGallery, isCorrelate}) => {
  const [isEnlarged, setIsEnlarged] = useState(false);

  const toggleEnlarged = () => {
    setIsEnlarged(!isEnlarged);
  };

  const decodeBase64Image = (image) => {
    const cleanBase64 = image.replace(/^data:image\/(png|jpeg);base64,/, '');
    const byteCharacters = atob(cleanBase64);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    return URL.createObjectURL(blob);
  };

  const preventContextMenu = (event) => {
    event.preventDefault();
  };

  // onHover image Per informazioni dettagliate sui termini di utilizzo, si prega di consultare la nostra pagina Credits.


  return (
    <>
    <div className={isCover ? "imageCover" : "image-container"}  
      onContextMenu={preventContextMenu}>
      <img
        src={decodeBase64Image(base64Image)}
        alt={altText}
        className={className}
        onClick={toggleEnlarged}
      />
      
 
      {isEnlarged && (
        <div className="enlarged-image-overlay" onClick={toggleEnlarged}>
          <img
            src={decodeBase64Image(base64Image)}
            alt={altText}
            className="enlarged-image"
          />
        </div>
      )}
    </div>
    {!isGallery && !isCorrelate && <a href='/credits' className="image-disclaimer">© Credits</a>}
    {isCorrelate && <a href='/credits' className="image-correlate">© Credits</a>}
    {/* {isGallery && <a href='/credits' className="image-correlate">© Credits</a>} */}
    </>
  );
};

export default ImageDecoder;
