// ArtworkDetails.js
import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { OpereCorrelate } from '../Fields/OpereCorrelate/OpereCorrelate';
import ImageDecoder from '../Utils/ImageDecoder/ImageDecoder';
import Bibliography from '../Fields/Bibliography/Bibliography';
import ElectronicRes from '../Fields/ElectronicRes/ElectronicRes';
import Inscriptions from '../Fields/Inscriptions/Inscriptions';
import DatiArcheometrici from '../Fields/DatiArcheometrici/DatiArcheometrici';
import TechnicalMaterials from '../Fields/DatiTecMat/DatiTecMat';
import LuogoConservazione from '../Fields/LuogoConservazione/LuogoConservazione';
import ArtworkDescription from '../Fields/Description/Description';
import ArtworkDates from '../Fields/Datazione/Datazione';
import ArtworkAuthors from '../Fields/Author/Author';
import ArtworkPlacesOfOrigin from '../Fields/PlaceOrigin/PlaceOrigin';
import ArtworkProvenance from '../Fields/Provenance/Provenance';
import { useLanguage } from '../../LanguageContext';

const ArtworkDetails = ({ currentArtwork }) => {

    const isArrayEmpty = (arr) => arr.length === 0;
    const { selectedLanguage, handleLanguageChange } = useLanguage();
    const getLangContent = (content) => content ? content[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';

    return (
        <Row>
            <Col className="col-12 col-md-8 boxField">
                <Col>
                    <h4 className="pb-3">
                        {getLangContent(currentArtwork.name)}
                    </h4>
                </Col>
                {currentArtwork.location && (
                    <Col className="col-md-12">
                        <label>
                            <strong>{selectedLanguage === 'it' ? 'Luogo di conservazione ' : 'Location '}</strong>
                        </label>
                        <span>
                            {currentArtwork.location.city_town !== false ? `${currentArtwork.location.city_town}, ${currentArtwork.location.name_off}` : 
                            `${currentArtwork.location.name_off}`}
                        </span>
                    </Col>
                    )
                }
                {currentArtwork.inventory_number && (
                    <Col className="col-md-12">
                    <label>
                        <strong>{selectedLanguage === 'it' ? 'N° Inventario ' : 'Inventory No. '}</strong>
                    </label>
                    <div className='mt-3'>
                        {currentArtwork.inventory_number}
                    </div>
                    </Col>
                )}
                <Col className="col-12 col-md-12 pt-5">
                    <a target='_blank' href={`/artwork/${currentArtwork.id}`} className="badge badge-warning appro">
                        {selectedLanguage === 'it' ? 'Approfondisci' : 'Read more'}
                    </a>
                </Col>
                <br />
            </Col>
            <Col className="col-12 col-md-4 text-center">
                {currentArtwork.pictures
                    .filter(picture => picture.is_cover === true && picture.image)
                    .map(picture => (
                        <ImageDecoder
                            isCover={true}
                            key={picture.id}
                            className='img-fluid'
                            base64Image={picture.image}
                            alt={picture.title}
                        />
                ))}
            </Col>
            
            
            
            

            
        </Row>
    );
    };

export default ArtworkDetails;
