import { Carousel, Fade } from "react-bootstrap";
import React from "react";
import './carouselPages.css';

const CarouselPages = () => {
  return (
    <Carousel className='carouselCustomPage' fade={true} controls={false} slide={true} indicators={false} >

      <Carousel.Item interval={1000} className='carouselCustomPageItem'>
        <img
          className="d-block w-100 imageLog1"
          src="https://www.bisanziosvelata.it/logo_colore_Nero_suFondoTrasp.png"
          alt="Bisanzio Svelata"
        />

      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog2"
          src="https://www.bisanziosvelata.it/logo_colore_Nero_suFondoTrasp.png"
          alt="Bisanzio Svelata"
        />

      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog3"
          src="https://www.bisanziosvelata.it/logo_colore_Nero_suFondoTrasp.png"
          alt="Bisanzio Svelata"
        />

      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog1"
          src="https://www.bisanziosvelata.it/logo_colore_Nero_suFondoTrasp.png"
          alt="Bisanzio Svelata"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog2"
          src="https://www.bisanziosvelata.it/logo_colore_Nero_suFondoTrasp.png"
          alt="Bisanzio Svelata"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog3"
          src="https://www.bisanziosvelata.it/logo_colore_Nero_suFondoTrasp.png"
          alt="Bisanzio Svelata"
        />
      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog1"
          src="https://www.bisanziosvelata.it/logo512.png"
          alt="Bisanzio Svelata"
        />

      </Carousel.Item>


      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog2"
          src="https://www.bisanziosvelata.it/logo512.png"
          alt="Bisanzio Svelata"
        />

      </Carousel.Item>

      <Carousel.Item interval={1000}>
        <img
          className="d-block w-100 imageLog3"
          src="https://www.bisanziosvelata.it/logo512.png"
          alt="Bisanzio Svelata"
        />

      </Carousel.Item>

      

    </Carousel>
  );
};

export default CarouselPages;
