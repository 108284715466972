import React from "react";
import './Loading.css';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';

const Loading = () => (
  <Container className="full-height d-flex justify-content-center align-items-center">
    <div className="loading">
      <Spinner animation="border" variant="primary" />
    </div>
  </Container>
);

export default Loading;
