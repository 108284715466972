import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './cookiepolicypage.scss';
import { useLanguage } from '../../LanguageContext';
import Footer from '../../components/Footer/Footer';

function CookiePolicyPage() {
  const { selectedLanguage } = useLanguage();

  const primaColonna = 10;
  const secondaColonna = 10;

  return (
    <>    
        <Row className="justify-content-center align-items-center rowTitle">
            <Col md={primaColonna} sx={primaColonna} lg={primaColonna}>
              <h1 className="titleO text-center" style={{fontSize:"35px"}}>
                {selectedLanguage === 'it' ? 'Politica sui cookie' : 'Cookie Policy' }
              </h1>
            </Col>
            <Col md={secondaColonna} sx={secondaColonna} lg={secondaColonna} className="text-justify">
              {/* Inserisci qui il testo della tua politica sui cookie */}
              <p>
              I cookie sono piccoli pezzi di testo inviati dai nostri server al tuo computer o dispositivo quando accedi ai nostri servizi. Vengono memorizzati nel tuo browser e poi rimandati ai nostri server in modo da poter fornire contenuti contestuali. Senza i cookie, usare il web sarebbe un'esperienza molto più frustrante. Li usiamo per supportare le tue attività sul nostro sito web. Per esempio, la tua sessione (in modo da non dover effettuare nuovamente il login) o il tuo carrello della spesa.
I cookie vengono utilizzati anche per aiutarci a capire le tue preferenze in base all'attività precedente o corrente sul nostro sito web (le pagine che hai visitato), la tua lingua e il tuo paese, il che ci permette di fornirti servizi migliori. Utilizziamo i cookie anche per aiutarci a compilare dati aggregati sul traffico del sito e l'interazione del sito in modo da poter offrire migliori esperienze e strumenti del sito in futuro.

Ecco una panoramica dei cookie che possono essere memorizzati sul tuo dispositivo quando visiti il nostro sito web:  
              </p>
                <ul>
                <li><strong>Sessione e sicurezza (essenziale):</strong> Autentica gli utenti, protegge i dati degli utenti e permette al sito web di fornire i servizi che gli utenti si aspettano, come mantenere il contenuto del loro carrello o permettere l'upload di file.</li>
                <li><strong>Preferenze (essenziale):</strong> Ricorda le informazioni sull'aspetto o il comportamento preferito del sito web, come la lingua o la regione preferita.</li>
                <li><strong>Cronologia interazioni (opzionale):</strong> Utilizzato per raccogliere informazioni sulle tue interazioni con il sito web.</li>
                <li><strong>Pubblicità & marketing (facoltativo):</strong> Utilizzato per rendere la pubblicità più coinvolgente per gli utenti.</li>
                <li><strong>Analytics (opzionali):</strong> Capisci come i visitatori si impegnano con il nostro sito web, tramite Google Analytics.</li>
              </ul>
              <p>Puoi scegliere di farti avvertire dal tuo computer ogni volta che viene inviato un cookie, oppure puoi scegliere di disattivare tutti i cookie attraverso le impostazioni del tuo browser.</p>
            </Col>
        </Row>
        <Footer />
     </>
  );
}

export default CookiePolicyPage;
