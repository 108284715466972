import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import CarouselPages from '../../components/CarouselPages/CarouselPages';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../LanguageContext';

function ContactForm() {
    const { selectedLanguage } = useLanguage();
    const [formData, setFormData] = useState({
      name: '',
      email: '',
      message: ''
    });
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    };
  
    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log('Form Data:', formData);
    
        alert(selectedLanguage === 'it'
          ? 'Grazie per averci contattato! Ti risponderemo al più presto.'
          : 'Thank you for contacting us! We will get back to you as soon as possible.');
        
        // Reset the form data
        setFormData({
            name: '',
            email: '',
            message: ''
        });
    };
    
    return (
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formBasicName">
          <Form.Label>{selectedLanguage === 'it' ? 'Nome e Cognome' : 'Full Name'}</Form.Label>
          <Form.Control
            type="text"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            placeholder={selectedLanguage === 'it' ? 'Nome e Cognome' : 'Full Name'}
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label>{selectedLanguage === 'it' ? 'Indirizzo email' : 'Email Address'}</Form.Label>
          <Form.Control
            type="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            placeholder={selectedLanguage === 'it' ? 'Email' : 'Email'}
            required
          />
        </Form.Group>
  
        <Form.Group className="mb-3" controlId="formBasicMessage">
          <Form.Label>{selectedLanguage === 'it' ? 'Messaggio' : 'Message'}</Form.Label>
          <Form.Control
            as="textarea"
            name="message"
            value={formData.message}
            onChange={handleInputChange}
            placeholder={selectedLanguage === 'it' ? 'Messaggio' : 'Message'}
            rows={3}
            required
          />
        </Form.Group>
  
        <Button variant="primary" type="submit" style={{background:"#7f163d", borderColor:"#7f163d"}}>
          {selectedLanguage === 'it' ? 'Invia' : 'Send'}
        </Button>
      </Form>
    );
  }

export default ContactForm;
