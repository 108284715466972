import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import itFlag from './it.png';
import enFlag from './en.png';
import './styles.css';
import { useLanguage } from '../../LanguageContext';

function NavBarComponent() {
  const { selectedLanguage, handleLanguageChange } = useLanguage();

  const links = [
    { label: 'Home', url: '/', englishName: 'Home' },
    { label: 'Ricerca', url: '/research', englishName: 'Research' },
    { label: 'Mappa', url: '/map', englishName: 'Map' },
    { label: 'Progetto', url: '/project', englishName: 'Project' },
    { label: 'Staff', url: '/staff', englishName: 'Staff' },
    { label: 'Contatti', url: '/contact', englishName: 'Contact us' },
  ];

  return (
    <>
      {['xl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary">
          <Container fluid>
            <Navbar.Brand href="/">
            <p className="logoImg"></p>
              {/* <img
              className="logoImg"
              src="https://www.bisanziosvelata.it/logo_tra.png"
              alt="Bisanzio Svelata"
              /> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} className="custom-toggler-icon" />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                {/* <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {selectedLanguage === 'it' ? 'Menu' : 'Menu'}
                </Offcanvas.Title>
                 */}
              </Offcanvas.Header>

              <Nav className="me-auto">
                {links.map((link) => (
                  <Nav.Link key={link.label} href={link.url}>
                    {selectedLanguage === 'it' ? link.label : link.englishName}
                  </Nav.Link>
                ))}
                {/* <Nav.Link onClick={() => handleLanguageChange('it')>
                  <img alt="ita" className='flagStyle' src={itFlag} />
                </Nav.Link> */}
                <Nav.Link active={selectedLanguage === 'en'} onClick={() => handleLanguageChange('en')}>
                  <img alt="eng" className='flagStyle' src={enFlag} />
                </Nav.Link>
                <Nav.Link active={selectedLanguage === 'it'} onClick={() => handleLanguageChange('it')}>
                  <img alt="ita" className='flagStyle' src={itFlag} />
                </Nav.Link>

                {/* <NavDropdown
                  title={selectedLanguage === 'it' ? <><img alt="ita" className='flagStyle' src={itFlag} /><span> Italiano</span></> : <><img alt="eng" className='flagStyle' src={enFlag} /><span> English</span></>}
                  id="language-dropdown"
                >
                  <NavDropdown.Item
                    active={selectedLanguage === 'it'}
                    onClick={() => handleLanguageChange('it')}
                  >
                    <img alt="ita" className='flagStyle' src={itFlag} /> Italiano
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    active={selectedLanguage === 'en'}
                    onClick={() => handleLanguageChange('en')}
                  >
                    <img alt="eng" className='flagStyle' src={enFlag} /> English
                  </NavDropdown.Item>
                </NavDropdown> */}
              </Nav>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default NavBarComponent;
