import React, { useState, useEffect } from 'react';
import ImageDecoder from '../../Utils/ImageDecoder/ImageDecoder';
import './PicturesGallery.scss'; 
import Col from 'react-bootstrap/Col';
import { useLanguage } from '../../../LanguageContext';
import { FiDivideCircle } from 'react-icons/fi';

const PicturesGallery = ({ currentArtwork, isArrayEmpty }) => {

  const {selectedLanguage} = useLanguage();
  const getLangValue = (data) => {
    return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        window.addEventListener('resize', () => {
        if(window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        });
    }, []);

  return (
    !isArrayEmpty(currentArtwork.pictures) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Galleria immagini' : 'Pictures gallery'}</strong>
        </label>
        <Col className="col-12 pt-3 boxGall text-center">
        {currentArtwork.pictures.map((image, index) => (
          !isMobile ? (
          <Col key={index} className="col-4 col-md-4">
            <Col /* style={{zoom: 0.7}} */>
              {image.title && <p>{getLangValue(image.title)}</p>}
            </Col>
            <Col>
                <ImageDecoder
                key={image.id}
                className="img-fluid imgGall"
                base64Image={image.image}
                altText={image.title}
                isGallery={true}
                />
                <span style={{zoom: 0.8}}>
                  {/* {image.description && <p>{getLangValue(image.description)}</p>} */}
                  {image.credit && <p style={{marginBottom :'5px'}}>{image.credit} </p>}
                  <p>
                  <a href='/credits' className='AimgGall'>© Credits</a>
                  </p>
                </span>
            </Col>  
          </Col>
          ) :
          (
          <Col key={index} className="col-12 col-md-12">
            <Col>
              {image.title && <p>{getLangValue(image.title)}</p>}
            </Col>
            {/* add left and right arrow to slide in mobile */}
            <Col>
                <ImageDecoder
                key={image.id}
                className="img-fluid imgGall"
                base64Image={image.image}
                altText={image.title}
                isGallery={true}
                />
                <span style={{zoom: 0.8}}>
                  {/* {image.description && <p>{getLangValue(image.description)}</p>} */}
                  {image.credit && <p style={{marginBottom :'5px'}}>{image.credit} </p>}
                  <p>
                  <a href='/credits' className='AimgGall'>© Credits</a>
                  </p>
                </span>
            </Col>
          </Col>
          )
        ))}
        
      </Col>
        </Col>  
    )
    );
}

export default PicturesGallery;
