import React from 'react';
import Col from 'react-bootstrap/Col';
import { useLanguage } from '../../../LanguageContext';

const ArtworkProvenance = ({ currentArtwork, isArrayEmpty }) => {
  const {selectedLanguage} = useLanguage();
  // Helper function to get the correct language value
  const getLangValue = (data, lang) => {
    return lang === 'it' ? data['it_IT'] : data['en_GB'];
  };

  // Check if provenance data is valid
  const isValidProvenance = (provenance) => {
    return !isArrayEmpty(provenance) && provenance && provenance !== 'false';
  };

  // const formatText = (text) => {
  //   return { __html: text.replace(/\n/g, '<br>') };
  // };

  // // Render text with line breaks
  const renderTextWithLineBreaks = (text) => {
    try {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {/* <br /> */}
        </React.Fragment>
      ));
    } catch (e) {
      console.log(e);
    }
    
  };

  const provenanceText = getLangValue(currentArtwork.provenance, selectedLanguage);


  return (
    isValidProvenance(currentArtwork.provenance) && provenanceText != '' && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Provenienza' : 'Provenance'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
          <span>{renderTextWithLineBreaks(provenanceText)}</span>
        </Col>
      </Col>
    )
  );
};

export default ArtworkProvenance;


