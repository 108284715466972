import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './creditspage.scss';
import { useLanguage } from '../../LanguageContext';
import Footer from '../../components/Footer/Footer';

function CreditsPage() {
  const { selectedLanguage } = useLanguage();

  const primaColonna = 10
  const secondaColonna = 10

  return (
    <>    
    <Container className='pb-3' style={{minHeight:'85vh'}}>
      <Row className="justify-content-center align-items-center rowTitle">
          <Col className='col-12 col-md-12 pt-3'>
            <h1 className="titleO text-center" style={{fontSize:"30px"}}>
              {selectedLanguage === 'it' ? 'Crediti' : 'Credits' }
            </h1>
            
          </Col>
          <Col md={secondaColonna} sx={secondaColonna} lg={secondaColonna}>
            <h2 className="titleO text-center" style={{fontSize:"25px"}}>
              {selectedLanguage === 'it' ? "Diritti d'Autore e utilizzo delle immagini" : "Copyright and Image Use" }
            </h2>
              
            <p className='text-jusify'>
              Tutte le immagini presenti su questo sito sono di proprietà delle rispettive istituzioni e sono protette dalle leggi sui diritti d'autore. Le immagini sono utilizzate su questo sito per scopi illustrativi e non è consentito il download, la distribuzione o l'utilizzo al di fuori di questo contesto senza l'espresso consenso dei proprietari dei diritti d'autore. 
            </p>
          </Col>
      </Row>
    </Container>
    <Footer/>
    
    </>
  );
}

export default CreditsPage;
