import React from 'react';
import Col from 'react-bootstrap/Col';
import './ElectronicRes.scss';
import { useLanguage } from '../../../LanguageContext';

const ElectronicRes = ({ currentArtwork, isArrayEmpty }) => {
  const {selectedLanguage} = useLanguage();
  return (
    !isArrayEmpty(currentArtwork.electronic_resource_ids) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Sitografia' : 'Sites'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxSito">
            {currentArtwork.electronic_resource_ids.map((electronic, index) => (
            <p key={index}>
                <a href={electronic.link} target="_blank" rel="noopener noreferrer">
                {electronic.link}
                </a>
            </p>
            ))}
        </Col>
      </Col>
    )
  );
};

export default ElectronicRes;
