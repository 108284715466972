// NameFilter.js
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLanguage } from '../../LanguageContext';

const FreeSearchFilter = ({ freeSearch, onChangeFreeSearch, retrieveArtworks, onChangeLuogoConservazione, onChangeSearchDataFine, onChangeSearchDataInizio, onChangeSearchName, onChangeSelectedSubjectTag, onChangeSelectedTag }) => {
  const { selectedLanguage } = useLanguage();
  return (

        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={selectedLanguage === 'en' ? 'Free search' : 'Ricerca libera'}
            value={freeSearch}
            onChange={onChangeFreeSearch}
          />
          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => {
                onChangeSearchName({ target: { value: '' } });
                onChangeSearchDataInizio({ target: { value: '' } });
                onChangeSearchDataFine({ target: { value: '' } });
                onChangeSelectedTag({ target: { value: '' } });
                onChangeSelectedSubjectTag({ target: { value: '' } });
                onChangeLuogoConservazione({ target: { value: '' } });
                retrieveArtworks();
            }}
            >
              {selectedLanguage === 'it' ? 'Cerca' : 'Search'}
            </button>
          </div>
        </div>

  );
};

export default FreeSearchFilter;
