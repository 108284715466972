import React, { useState, useRef, useEffect } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaDownload, FaAngleLeft, FaAngleRight, FaAngleDoubleLeft, FaAngleDoubleRight, FaCompress, FaExpand } from 'react-icons/fa';
import loadingImage from './loader.gif'; // Loading image for PDF load
import './pdfcontainer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PdfContainer = ({ pdfUrl, defaultZoomMobile, defaultZoomDesktop }) => {
    const containerRef = useRef(null);
    const [contentScrollTop, setContentScrollTop] = useState(0);
    const [pdfLoading, setPdfLoading] = useState(true);
    const [numPages, setNumPages] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [zoom, setZoom] = useState(); // Initial zoom level
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        setZoom(isMobile ? defaultZoomMobile : defaultZoomDesktop);
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
            setZoom(isMobile ? defaultZoomMobile : defaultZoomDesktop);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [defaultZoomMobile, defaultZoomDesktop, isMobile]);

    useEffect(() => {
        if (containerRef.current) {
            setContentScrollTop(containerRef.current.scrollTop);
        }
    }, [currentPage]);

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTop = contentScrollTop;
        }
    }, [contentScrollTop]);

    const handleZoomIn = () => setZoom((prevZoom) => prevZoom + 0.1);
    const handleZoomOut = () => setZoom((prevZoom) => Math.max(0.1, prevZoom - 0.1));
    const handlePdfLoadSuccess = ({ numPages }) => {
        setPdfLoading(false);
        setNumPages(numPages);
    };
    const handlePdfLoadError = error => {
        console.error('Error loading PDF:', error);
        setPdfLoading(false);
    };
    const handlePrevPage = (e) => {
        if (currentPage > 1) {
            setCurrentPage(prevPage => prevPage - 1);
        }
        e.preventDefault();
    };
    const handleNextPage = (e) => {
        if (currentPage < numPages) {
            setCurrentPage(prevPage => prevPage + 1);
        }
        e.preventDefault();
    };
    const handleDownloadPdf = () => {
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'downloaded_document.pdf';
        link.click();
    };
    const toggleFullScreen = () => {
        const docElement = document.documentElement;
        if (!isFullScreen) {
            docElement.requestFullscreen?.() || docElement.mozRequestFullScreen?.() || docElement.webkitRequestFullscreen?.() || docElement.msRequestFullscreen?.();
        } else {
            document.exitFullscreen?.() || document.mozCancelFullScreen?.() || document.webkitExitFullscreen?.() || document.msExitFullscreen?.();
        }
        setIsFullScreen(!isFullScreen);
    };

    return (
        <Container ref={containerRef} className={`containerCu ${isFullScreen ? 'full-screen' : ''}`}>
            <Row md={12} style={{textAlign:'-moz-center'}} className={`pdf-container ${isFullScreen ? 'full-screen' : ''}`}>
                <Col md={12} className={`tools ${isFullScreen ? 'full-screen' : ''}`}>
                    <Row md={12} className='text-center'>
                        <Col md={12}>
                            <Button className="buttonPdf" onClick={handleZoomOut}>-</Button>
                            <span style={{ margin: '20px' }}>{(zoom * 100).toFixed(0)}%</span>
                            <Button className="buttonPdf" onClick={handleZoomIn}>+</Button>
                            <Button className='buttonPdf' onClick={handleDownloadPdf}><FaDownload /></Button>
                            {/* <Button className="buttonPdf" onClick={toggleFullScreen}>{isFullScreen ? <FaCompress /> : <FaExpand />}</Button> */}
                        </Col>
                        <Col md={12} style={{marginTop:"10px"}}>
                            {numPages > 1 && (
                                <div className="page-navigation">
                                    <Button className='buttonPdf' onClick={() => setCurrentPage(1)} disabled={currentPage === 1}><FaAngleDoubleLeft/></Button>
                                    <Button className='buttonPdf' onClick={handlePrevPage} disabled={currentPage === 1}><FaAngleLeft/></Button>
                                    <span style={{margin:'20px'}}>
                                        Page {currentPage} of {numPages}
                                    </span>
                                    <Button className='buttonPdf' onClick={handleNextPage} disabled={currentPage === numPages}><FaAngleRight/></Button>
                                    <Button className='buttonPdf' onClick={() => setCurrentPage(numPages)} disabled={currentPage === numPages}><FaAngleDoubleRight/></Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col md={12} className={`pdf-doc ${isFullScreen ? 'full-screen' : ''}`}>
                    {pdfLoading && <img alt="loading" src={loadingImage} className="loading-image" />}
                    <Document
                        file={pdfUrl}
                        onLoadSuccess={handlePdfLoadSuccess}
                        onLoadError={handlePdfLoadError}
                    >
                        <Page pageNumber={currentPage} scale={zoom} />
                    </Document>
                </Col>
            </Row>
        </Container>
    );
};

export default PdfContainer;
