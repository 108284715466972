import React, { useMemo } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';


const MapMultiPoint = ({ points, setActiveArtwork, setActiveArtworksList }) => {
    const defaultCenter = [41.50, 12.35]; // Sostituire con un centro di default appropriato
    const defaultZoom = 5; // Sostituire con un livello di zoom appropriato

    // Calcola il centro della mappa in base ai punti
    const mapCenter = useMemo(() => {
        if (points.length === 0) {
        return defaultCenter;
        }
        const latitudes = points.map(p => p.location.latitude);
        const longitudes = points.map(p => p.location.longitude);
        const avgLat = latitudes.reduce((a, b) => a + b, 0) / latitudes.length;
        const avgLng = longitudes.reduce((a, b) => a + b, 0) / longitudes.length;

        return [avgLat, avgLng];
    }, [points]);

    const handleMarkerClick = (poi, index) => {
        // Filtra tutte le opere con le stesse coordinate del punto cliccato
        
        const sameCoordinatesArtworks = points.filter((p, i) =>
          p.location.latitude === poi.location.latitude &&
          p.location.longitude === poi.location.longitude
        );
        // console.log(sameCoordinatesArtworks);
        setActiveArtwork(sameCoordinatesArtworks[0]);
        setActiveArtworksList(sameCoordinatesArtworks);
      };
      

    return (
        <MapContainer center={mapCenter} zoom={defaultZoom} style={{ height: 'inherit', width: '100%' }}>
        <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution="&copy; OpenStreetMap contributors"
        />
        {points.map((poi, index) => (
            poi.location && poi.location.latitude && poi.location.longitude && (
                <Marker
                    key={index}
                    position={[poi.location.latitude, poi.location.longitude]}
                    eventHandlers={{
                        click: () => handleMarkerClick(poi, index),
                    }}
                >
                <Popup>
                    {poi.location.city_town !== false ? `${poi.location.city_town}, ${poi.location.name_off}` : `${poi.location.name_off}`}
                </Popup>
                </Marker>
            )
            ))}

        </MapContainer>
    );
    };

export default MapMultiPoint;
