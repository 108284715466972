import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import ArtworkDataService from '../../services/ArtworkService';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NameFilter from '../Filters/NameFilter';
import ArtworkListMap from '../../components/Artwork/ArtworkListMap';
import ArtworkDetails from '../Artwork/ArtworkDetails';
import ArtworkList from '../Artwork/ArtworkList';
import PaginationComponent from '../Artwork/PaginationComponent';

import FilterMain from '../Filters/FiltersMain';

import './mapArtwork.scss';

import { useLanguage } from '../../LanguageContext';

const MapArtwork = () => {
  const [artworks, setArtworks] = useState([]);
  const [currentArtwork, setCurrentArtwork] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [searchName, setSearchName] = useState('');
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(100);
  const [totalArtworksCount, setTotalArtworksCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [activeArtworksList, setActiveArtworksList] = useState([]);
  const [searchData_inizio, setSearchData_inizio] = useState('');
  const [searchData_fine, setSearchData_fine] = useState('');
  const { selectedLanguage } = useLanguage();

  const [selectedTag, setSelectedTag] = useState('');
  const [tags, setTags] = useState([]);

  const [subjectTags, setSubjectTags] = useState([]);
  const [selectedSubjectTag, setSelectedSubjectTag] = useState('');

  const [search_luogo_conservazione, setSearch_luogo_conservazione] = useState('');
  const [freeSearch, setFreeSearch] = useState('');

  const onChangeSelectedTag = (e) => {
    const selectedTag = e.target.value;
    setSelectedTag(selectedTag);
  };

  const onChangeFreeSearch = (e) => {
    const freeSearch = e.target.value;
    setFreeSearch(freeSearch);
  };

  const onChangeSelectedSubjectTag = (e) => {
    const selectedSubjectTag = e.target.value;
    setSelectedSubjectTag(selectedSubjectTag);
  };
  
  const onChangeSearchName = (e) => {
    const searchName = e.target.value;
    setSearchName(searchName);
  };

  const onChangeSearchDataInizio = (e) => {
    const searchData_inizio = e.target.value;
    setSearchData_inizio(searchData_inizio);
  };

  const onChangeSearchDataFine = (e) => {
    const searchData_fine = e.target.value;
    setSearchData_fine(searchData_fine);
  };

  const onChangeLuogoConservazione = (e) => {
    const luogo_conservazione = e.target.value;
    setSearch_luogo_conservazione(luogo_conservazione);
  };

  const retrieveTags = () => {
    ArtworkDataService.getTags()
      .then((response) => {
        // console.log(response.response)
        setTags(response.response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const retrieveSubjectTags = () => {
    ArtworkDataService.getSubjectTags()
      .then((response) => {
        // console.log(response.response)
        setSubjectTags(response.response);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const retrieveArtworks = (page) => {
    setLoading(true);
    setCurrentPage(page);
    ArtworkDataService.getAll(page, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch)
      .then((response) => {
        setArtworks(response.artworks);
        setTotalArtworksCount(response.totalArtworksCount);
        console.log(response.artworks);
      })
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    retrieveTags();
    retrieveSubjectTags();
  }, []);

  const handlePageChange = (page, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch) => {
    retrieveArtworks(page, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch);
  };

  useEffect(() => {
    retrieveArtworks(currentPage, pageSize, searchName, searchData_inizio, searchData_fine, selectedTag, selectedSubjectTag, search_luogo_conservazione, selectedLanguage, freeSearch);
  }, [currentPage, pageSize]);

  const setActiveArtwork = (artwork, index) => {
    setCurrentArtwork(artwork);
    setCurrentIndex(index);
  };

  return (
    <Container>

    <FilterMain
        searchName={searchName}
        onChangeSearchName={onChangeSearchName}
        searchData_fine={searchData_fine}
        searchData_inizio={searchData_inizio}
        onChangeSearchDataInizio={onChangeSearchDataInizio}
        onChangeSearchDataFine={onChangeSearchDataFine}
        retrieveArtworks={retrieveArtworks}
        tags={tags}
        subjectTags={subjectTags}
        selectedTag={selectedTag}
        selectedSubjectTag={selectedSubjectTag}
        onChangeSelectedTag={onChangeSelectedTag}
        onChangeSelectedSubjectTag={onChangeSelectedSubjectTag}
        search_luogo_conservazione={search_luogo_conservazione}
        onChangeLuogoConservazione={onChangeLuogoConservazione}
        freeSearch={freeSearch}
        onChangeFreeSearch={onChangeFreeSearch}
      />
      
      <Row>
        <Col md={12} className="text-left">
            <ArtworkListMap
              artworks={artworks}
              currentIndex={currentIndex}
              setActiveArtworksList={setActiveArtworksList}
              setCurrentArtwork={setCurrentArtwork}
              setActiveArtwork={setActiveArtwork}
              setCurrentIndex={setCurrentIndex}
              loading={loading}
            />
            {totalArtworksCount > pageSize && (
              <PaginationComponent
                currentPage={currentPage}
                pageSize={pageSize}
                totalArtworksCount={totalArtworksCount}
                handlePageChange={handlePageChange}
              />
            )}
        </Col>
      </Row>


      <Row>
      {currentArtwork ?
        <Col md={4} className="text-left">
            <ArtworkList
              artworks={activeArtworksList}
              currentIndex={currentIndex}
              setCurrentArtwork={setCurrentArtwork}
              setActiveArtwork={setActiveArtwork}
              setCurrentIndex={setCurrentIndex}
              loading={loading}
            />
            {totalArtworksCount > pageSize && (
              <PaginationComponent
                currentPage={currentPage}
                pageSize={pageSize}
                totalArtworksCount={totalArtworksCount}
                handlePageChange={handlePageChange}
              />
            )}
        </Col>
        : null}
        <Col md={8} className="text-left boxDetails" >
          {currentArtwork ? (
            <ArtworkDetails currentArtwork={currentArtwork}/>
          ) : (
            <div>
              <br />
              <p>{selectedLanguage === 'it' ? 'Per iniziare l\'esplorazione, selezionare un luogo' : 'Please click on a place...'}</p>
            </div>
          )}
        </Col>
      </Row>

    </Container>
  );
};

export default MapArtwork;
