import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './projectpage.scss';

import CarouselPages from '../../components/CarouselPages/CarouselPages';
import { useLanguage } from '../../LanguageContext';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import PdfContainer from '../../components/PDFcontainer/PdfContainer';


function ProjectPage() {
  const { selectedLanguage } = useLanguage();

  return (
    <>
    <Helmet>
      {/* Titolo */}
      <title>Bisanzio Svelata - Progetto</title>

      {/* Meta Tags */}
      <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="Bisanzio Svelata - Progetto" />
      <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="og:url" content="https://www.bisanziosvelata.it/project" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta property="twitter:title" content="Bisanzio Svelata - Progetto" />
      <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="twitter:url" content="https://www.bisanziosvelata.it/project" />

      {/* URL Canonico */}
      <link rel="canonical" href="https://www.bisanziosvelata.it/project" />
    </Helmet>
    <Container fluid className="bg-container">
      <Row className="justify-content-center align-items-center rowTitleO">
          <Col md={12} sx={12} lg={12} className='homeScritta'>
            <Col md={7} sx={7} lg={7}>
              <h1 className="titleO">
                {selectedLanguage === 'it' ? 'Navigare nell’Italia bizantina'
              : 'Navigating through Byzantine Italy'}
              </h1>
              <h2 className="subtitleO" >
                <span>{selectedLanguage === 'it' ? 'Un catalogo online per la conoscenza':  'An Online Catalog to Study'}</span>
                <br></br>
                <span>{selectedLanguage === 'it' ? 'e la valorizzazione di un patrimonio artistico sommerso':  'and Enhance a Submerged Artistic Heritage'}</span>
              </h2>
            </Col>
            <Col md={4} sx={4} lg={4}>
              <CarouselPages />
            </Col>
          </Col>
      </Row>
      <Row className="justify-content-center align-items-center">
        <Col md={6} sx={12} lg={6} className='pt-3'>
          <br></br>
          <h3 style={{textAlign: 'center'}}>
            Convegno internazionale di studi, Roma, 4-6 giugno 2024
          </h3>
          <br></br>
          <PdfContainer pdfUrl="./Poster.pdf" defaultZoomMobile={0.10} defaultZoomDesktop={0.2} />
          <br></br><br></br>
        </Col>
    
        <Col md={6} sx={12} lg={6} className='pt-3'>
          <br></br>
          <h3 style={{textAlign: 'center'}}>
            Antonio Iacobini - Verso un museo digitale 2022
          </h3>
          <br></br>
          <PdfContainer pdfUrl="./Antonio Iacobini - Verso un museo digitale 2022.pdf" defaultZoomMobile={0.20} defaultZoomDesktop={0.35}/>
          <br></br><br></br>
        </Col>
      </Row>
    </Container>
    <Footer />
    </>
  );
}

export default ProjectPage;
