import React from 'react';
import './PlaceOrigin.scss';
import Col from 'react-bootstrap/Col';
import { useLanguage } from '../../../LanguageContext';

const ArtworkPlacesOfOrigin = ({ currentArtwork, isArrayEmpty }) => {
  
  const {selectedLanguage} = useLanguage();
  const getLangValue = (data) => {
    return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  };
  
  return (
    !isArrayEmpty(currentArtwork.place_of_origin_off_notes_ids) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>
          {/* {selectedLanguage === 'it'
            ? currentArtwork.place_of_origin_off_notes_ids.length > 1
              ? 'Luoghi d\'origine'
              : 'Luogo d\'origine'
            : currentArtwork.place_of_origin_off_notes_ids.length > 1
              ? 'Places of origin'
              : 'Place of origin'
          } */}
          {selectedLanguage === 'it'
            ? 'Luogo d\'origine'
            : 'Places of origin'
          }
        </strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
          {currentArtwork.place_of_origin_off_notes_ids.map((place, index) => (
            <p className='elementsMat' key={index}>
              {getLangValue(place.note)}
            </p>
          ))}
        </Col>
      </Col>
    )
  );
};

export default ArtworkPlacesOfOrigin;
