import React from "react";
import {useEffect, useState} from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './artwork.scss';
import { OpereCorrelate } from "../Fields/OpereCorrelate/OpereCorrelate";
import PicturesGallery from "../Fields/PicturesGallery/PicturesGallery";
import Bibliography from "../Fields/Bibliography/Bibliography";
import ElectronicRes from "../Fields/ElectronicRes/ElectronicRes";
import Inscriptions from "../Fields/Inscriptions/Inscriptions";
import DatiArcheometrici from "../Fields/DatiArcheometrici/DatiArcheometrici";
import TechnicalMaterials from "../Fields/DatiTecMat/DatiTecMat";
import LuogoConservazione from "../Fields/LuogoConservazione/LuogoConservazione";
import Esposizioni from "../Fields/Esposizioni/Esposizioni";
import Conservation from "../Fields/Conservation/Conservation";
import ArtworkDescription from "../Fields/Description/Description";
import ArtworkDates from "../Fields/Datazione/Datazione";
import ArtworkAuthors from "../Fields/Author/Author";
import ArtworkPlacesOfOrigin from "../Fields/PlaceOrigin/PlaceOrigin";
import ArtworkProvenance from "../Fields/Provenance/Provenance";
import Compilazione from "../Fields/Compilazione/Compilazione";
import ImageDecoder from "../Utils/ImageDecoder/ImageDecoder";
import DatiIconografici from "../Fields/DatiIconografici/DatiIconografici";
import NoteAggiuntive from "../Fields/NoteAggiuntive/NoteAggiuntive";
import CiteArt from "../CiteArt/CiteArt";
import { useLanguage } from '../../LanguageContext';

function ArtworkContent ({currentArtwork}) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        window.addEventListener('resize', () => {
        if(window.innerWidth < 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        });
    }, []);

    const { selectedLanguage, handleLanguageChange } = useLanguage();

    const getLangContent = (content) => content ? content[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';

    const isArrayEmpty = (arr) => {
        // First, check if the input is actually an array
        if (!Array.isArray(arr)) {
            return false;
        }
    
        // Check if the array is empty
        if (arr.length === 0) {
            return true;
        }
    
        // Check each element in the array
        return arr.every(obj => {
            // Ensure that the element is an object
            if (typeof obj !== 'object' || obj === null) {
                return false;
            }
    
            // Iterate over each property in the object
            return Object.values(obj).every(prop => {
                // Check if the property is an object itself and handle it recursively
                if (typeof prop === 'object' && prop !== null) {
                    return isArrayEmpty(Object.values(prop));
                }
                // Check for other falsy values or empty strings
                return !prop;
            });
        });
    };

    const [showExtendedInfo, setShowExtendedInfo] = useState(false);

    return (
        <Container>
                <Row>
                    <Col className={isMobile ? "" : 'col-12 col-md-12 titCom'}>
                        <Col className={isMobile ? "" : 'col-9 col-md-9 boxField'}>
                            <h1 className="pb-3">
                                {getLangContent(currentArtwork.name)}
                            </h1> 
                            
                            <label className="labelShow">
                                <input
                                type="checkbox"
                                checked={showExtendedInfo}
                                onChange={() => setShowExtendedInfo(!showExtendedInfo)}
                                />
                                {selectedLanguage === 'it' ? <strong> Mostra scheda estesa</strong> : <strong> Show extended info</strong>}
                            </label>
                        </Col>
                        <Col className={isMobile ? "" : 'col-3 col-md-3'}>
                            <span className="completion" style={isMobile ? {textAlign: 'unset'} : {textAlign: 'right'}
                            }><Compilazione isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} /></span>
                            
                        </Col>
                    </Col>

                    <ArtworkDates isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />
                    <PicturesGallery currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />             
                    <ArtworkDescription isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} isShort={showExtendedInfo ? false : true} />

                    <ArtworkPlacesOfOrigin isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />
                    <ArtworkProvenance isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />

                    {showExtendedInfo ? (
                        <LuogoConservazione isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />
                    ) : (
                        <LuogoConservazione isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} isDetail={true} />
                    )}


                    <TechnicalMaterials isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />
                    <DatiArcheometrici currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                    <Inscriptions isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />

                    {showExtendedInfo && (
                        <>
                            <DatiIconografici currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                            <Conservation currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                            <Esposizioni currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                            <NoteAggiuntive currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                            <Bibliography isShort={false} currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                        </>
                    )}
                    
                    {!showExtendedInfo && (
                        <Bibliography isShort={true} currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                    )}
                    
                    <ElectronicRes currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                    <OpereCorrelate currentArtwork={currentArtwork} isArrayEmpty={isArrayEmpty} />
                    <ArtworkAuthors isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />
                    <CiteArt isArrayEmpty={isArrayEmpty} currentArtwork={currentArtwork} />
                    
                </Row>
        </Container>
    );
    }
    

export default ArtworkContent;
