import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage/HomePage';
import ResearchPage from './pages/ResearchPage/ResearchPage';
import MapPage from './pages/MapPage/MapPage';
import ProjectPage from './pages/ProjectPage/ProjectPage';
import StaffPage from './pages/StaffPage/StaffPage';
import ContactPage from './pages/ContactPage/ContactPage';
import ArtworkListPage from './pages/ArtworkListPage/ArtworkListPage';
import ArtworkPage from './pages/ArtworkPage/ArtworkPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage/PrivacyPolicyPage';
import CookiePolicyPage from './pages/CookiePolicyPage/CookiePolicyPage';
import CreditsPage from './pages/CreditsPage/CreditsPage';
import ArtworkService from './services/ArtworkService';
import NavBarComponent from './components/NavBar/Navbar';
import { useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

const App = () => {
  
  //  useEffect(() => {
  //   let session_id = localStorage.getItem("session_id");
  //   if (!session_id) {
  //     ArtworkService.initSession().then(response => {
  //       localStorage.setItem("session_id", response.data.result.session_id.toString())
  //     })
  //     return
  //   }
  // }, []);

  return (
    <>
      <NavBarComponent/>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/research" element={<ResearchPage />} />
        <Route path="/map" element={<MapPage />} />
        <Route path="/project" element={<ProjectPage />} />
        <Route path="/staff" element={<StaffPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/artwork" element={<ArtworkListPage />} />
        <Route path="/artwork/:id" element={<ArtworkPage />} />
        {/* <Route path="/privacy-policy" element={<PrivacyPolicyPage />} /> */}
        <Route path="/cookie-policy" element={<CookiePolicyPage />} />
        <Route path="/credits" element={<CreditsPage />} />
      </Routes>
    </>
  );
};

export default App;
