import React from 'react';
import './Pagination.scss';

const Pagination = ({ currentPage, pageSize, totalArtworksCount, onPageChange }) => {
  const totalPages = Math.ceil(totalArtworksCount / pageSize);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages && page !== currentPage) {
      onPageChange(page);
    }
  };

  const renderPaginationItems = () => {
    const paginationItems = [];
    for (let i = 1; i <= totalPages; i++) {
      paginationItems.push(
        <li
          key={i}
          className={`page-item ${currentPage === i ? 'active' : ''}`}
          onClick={() => handlePageClick(i)}
        >
          <button className="page-link">{i}</button>
        </li>
      );
    }
    return paginationItems;
  };

  return (
    <nav>
      <ul className="pagination">
        <li
          className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}
          onClick={() => handlePageClick(currentPage - 1)}
        >
          <button className="page-link" aria-label="Previous" disabled={currentPage === 1}>
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>
        {renderPaginationItems()}
        <li
          className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}
          onClick={() => handlePageClick(currentPage + 1)}
        >
          <button className="page-link" aria-label="Next" disabled={currentPage === totalPages}>
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
