  import React, { useRef } from 'react';

  import Container from 'react-bootstrap/Container';
  import Row from 'react-bootstrap/Row';
  import Col from 'react-bootstrap/Col';

  import './researchpage.scss';

  import CarouselPages from '../../components/CarouselPages/CarouselPages';
  import { useLanguage } from '../../LanguageContext';
  import Footer from '../../components/Footer/Footer';
  import { Helmet } from 'react-helmet';
  import ArtworksListPage from '../ArtworkListPage/ArtworkListPage';
  import { FaHandPointDown } from "react-icons/fa";
  
  function ResearchPage() {
    const { selectedLanguage } = useLanguage();
    const rowRef = useRef(null); // Riferimento alla riga successiva
  
    const scrollToNextRow = () => {
      if (rowRef.current) {
        rowRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    };
  
    const handleButtonClick = () => {
      scrollToNextRow();
    };
  
    return (
      <>
      <Helmet>
        {/* Titolo */}
        <title>Bisanzio Svelata - Ricerca</title>

        {/* Meta Tags */}
        <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino" />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Bisanzio Svelata - Ricerca" />
        <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
        <meta property="og:url" content="https://www.bisanziosvelata.it/research" />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta property="twitter:title" content="Bisanzio Svelata - Ricerca" />
        <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
        <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
        <meta property="twitter:url" content="https://www.bisanziosvelata.it/research" />

        {/* URL Canonico */}
        <link rel="canonical" href="https://www.bisanziosvelata.it/research" />
      </Helmet>
      
      <Container className="bg-container">
        
        <Row className="justify-content-center align-items-center rowTitleO">


          <Col md={12} sx={12} lg={12} className='homeScritta'>
            
            <Col md={5} sx={5} lg={5}>
              <h1 className="titleO">
                {selectedLanguage === 'it' ? 'Ricerca' : 'Research' }
              </h1>
              <h2 className="subtitleO" >
                {selectedLanguage === 'it'
                  ? 'MUSEO VIRTUALE ITALIANO DI ARTE BIZANTINA'
                  : 'ITALIAN VIRTUAL MUSEUM OF BYZANTINE ART'}
              </h2>
              
            </Col>
            <Col md={4} sx={4} lg={4}>
              <CarouselPages />
            </Col>
          </Col>
        </Row>
  
        <div ref={rowRef}>
          <Row className="justify-content-center align-items-center">
              <ArtworksListPage />
          </Row>
        </div>
      </Container>
      <Footer />
      </>
    );
  }
  
  export default ResearchPage;
    