import React from "react";
import { Button, Card, Container } from "react-bootstrap";
import { useLanguage } from '../../LanguageContext';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './featured.css';

const DisclaimerRessearch = () => {
  const { selectedLanguage } = useLanguage();
  return (
      <Col md={12} style={{paddingLeft: '0px',  paddingRight: '0px'}}>
        {selectedLanguage === 'it' ? (
            <>
              <p>
                Il gruppo di ricerca è composto da quattro Unità (
                <a className = "linkUniv" href="https://www.uniroma1.it/" target="_blank" rel="noopener noreferrer">Sapienza Università di Roma</a>, <a className = "linkUniv" href="https://www.uniroma3.it/" target="_blank" rel="noopener noreferrer">Università degli Studi Roma Tre</a>, <a className = "linkUniv" href="https://www.iulm.it/en" target="_blank" rel="noopener noreferrer">IULM-Milano</a>, <a className = "linkUniv" href="https://www.unisalento.it/" target="_blank" rel="noopener noreferrer">Università del Salento</a>) e si avvale della partecipazione di numerosi specialisti in ambiti diversi, dalla storia dell’arte all’archeologia, dall’epigrafia alla filologia, dalla museologia all’economia del turismo.
              </p>
            </>
          ) : (
            <>
              <p>
                Our research team comprises four universities (
                <a className = "linkUniv" href="https://www.uniroma1.it/" target="_blank" rel="noopener noreferrer">Sapienza Università di Roma</a>,
                <a className = "linkUniv" href="https://www.uniroma3.it/" target="_blank" rel="noopener noreferrer">Università degli Studi Roma Tre</a>,
                <a className = "linkUniv" href="https://www.iulm.it/en" target="_blank" rel="noopener noreferrer">IULM-Milan</a>,
                <a className = "linkUniv" href="https://www.unisalento.it/" target="_blank" rel="noopener noreferrer">Università del Salento</a>) and includes numerous specialists in different fields, ranging from art history and archaeology to epigraphy, philology, museology, and tourism economics.
              </p>
              
            </>
          )}
            <br></br>
            <Row className="justify-content-md-center text-center" >
              <Col>
                <a className = "linkUnivB" href="https://www.uniroma1.it/" target="_blank" rel="noopener noreferrer">
                  <Image className="imageUni" src="https://www.bisanziosvelata.it/01_uniroma1.png" roundedCircle />
                </a>
              </Col>
              <Col className="custumCol">
                <a className = "linkUnivB" href="https://www.uniroma3.it/" target="_blank" rel="noopener noreferrer">
                  <Image className="imageUni" src="https://www.bisanziosvelata.it/02_uniroma3.jpg" roundedCircle />
                </a>
              </Col>
              <Col className="custumCol">
                <a className = "linkUnivB" href="https://www.iulm.it/en" target="_blank" rel="noopener noreferrer">
                  <Image className="imageUni" src="https://www.bisanziosvelata.it/03_iulm.png" roundedCircle />
                </a>
              </Col>
              <Col className="custumCol">
                <a className = "linkUnivB" href="https://www.unisalento.it/" target="_blank" rel="noopener noreferrer">
                  <Image className="imageUni" src="https://www.bisanziosvelata.it/04_unisa.png" roundedCircle />
                </a>
              </Col>
            </Row>
      </Col>
  );
};

export default DisclaimerRessearch;
