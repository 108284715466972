import React from "react";
import './style.css';

const ErrorLoading = () => (
  <div className="error-loading">
    <p>Service temporarily unavailable, please try again later.</p>
  </div>
);

export default ErrorLoading;
