import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import DimensionLabel from '../DimensionLabel/DimensionLabel'; // Assumi che DimensionLabel sia un componente già esistente per le dimensioni
import './DatiArcheometrici.scss';
import { useLanguage } from '../../../LanguageContext';

const DatiArcheometrici = ({ currentArtwork, isArrayEmpty }) => {
    const {selectedLanguage} = useLanguage();
    const getLangContent = (content) => content ? content[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';

    return (
    !isArrayEmpty(currentArtwork.elements) && (
        <Col className="col-12 col-md-12">
            <label>
                <strong>{selectedLanguage === 'it' ? 'Dati archeometrici' : 'Measures'}</strong>
            </label>

            <Col className="col-12 col-md-12 boxArcheo">
                {currentArtwork.elements.map((element, index) => (
                <Col key={index} className="rowArcheo">
                    <>{getLangContent(element.name)}</>
                    {element.width !== 0 && <DimensionLabel label={selectedLanguage === 'it' ? "Larghezza" : "Width"} value={element.width} />}
                    {element.height !== 0 && <DimensionLabel label={selectedLanguage === 'it' ? "Altezza" : "Height"} value={element.height} />}
                    {element.depth !== 0 && <DimensionLabel label={selectedLanguage === 'it' ? "Profondità" : "Depth"} value={element.depth} />}
                    {element.thickness !== 0 && <DimensionLabel label={selectedLanguage === 'it' ? "Spessore" : "Thickness"} value={element.thickness} />}
                    {element.diameter !== 0 && <DimensionLabel label={selectedLanguage === 'it' ? "Diametro" : "Diameter"} value={element.diameter} />}
                </Col>
                ))}
            </Col>

        </Col>
    )
  );
};

export default DatiArcheometrici;
