import React from 'react';
import Col from 'react-bootstrap/Col';
import './Description.scss';
import { useLanguage } from '../../../LanguageContext';

const ArtworkDescription = ({ currentArtwork, isArrayEmpty, isShort }) => {
  const {selectedLanguage} = useLanguage();
  const getDescription = () => {
    let description = '';

    // let descriptionLong = '';

    if (isShort && !isArrayEmpty(currentArtwork.short_description)) {
      description = currentArtwork.short_description[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'];
      return description.replace(/\n/g, '<br>');
    } 
    else if ( !isShort && !isArrayEmpty(currentArtwork.long_description)) {
      description = currentArtwork.long_description[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'];
      return description.replace(/\n/g, '<br>');
    }

    else {
      return null;
    }
    
  };
  

  const description = getDescription();

  return (
    description && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Descrizione' : 'Description'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxDesc">
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </Col>
      </Col>
    )
  );
};

export default ArtworkDescription;
