import React, { useEffect, useState } from "react";
import "leaflet/dist/leaflet.css";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "./MapComponent.scss";
import { Icon, divIcon, point } from "leaflet";
import { useLanguage } from "../../LanguageContext";

function MapComponent({ lat, lng, name}) {
 
  const markers = [
    {
      geocode: [lat, lng],
      popUp: name
    }
  ];

  const customIcon = new Icon({
    iconUrl: "https://cdn-icons-png.flaticon.com/512/447/447031.png",
    iconSize: [38, 38] // size of the icon
  });

  // custom cluster icon
  const createClusterCustomIcon = function (cluster) {
    return new divIcon({
      html: `<span class="cluster-icon">${cluster.getChildCount()}</span>`,
      className: "custom-marker-cluster",
      iconSize: point(33, 33, true)
    });
  };

  const [zoomFocus, setZoomFocus] = useState([41.89, 12.5]);
  const [zoomValue, setZoomValue] = useState(13);

  useEffect(() => {
    setZoomValue(13);
  }, []);

  return (
      <MapContainer center={markers[0].geocode} zoom={15} >
        <TileLayer
          attribution=''
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
              {markers.map((marker) => (
                <Marker position={marker.geocode} icon={customIcon}>
                  <Popup>{marker.popUp}</Popup>
                </Marker>
              ))}
    </MapContainer>
  );
}

export default MapComponent;
