import React from 'react';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { useLanguage } from '../../LanguageContext';
import './teamsection.css';
import Badge from 'react-bootstrap/Badge';

function TeamSection() {
  const { selectedLanguage } = useLanguage();
   
  const getPlaceholderImage = () => {
    // You can provide a placeholder image URL here
    // If no image is available for a team member, this placeholder image will be shown
    return 'https://bisanziosvelata.web.app/logo_colore_Nero_suFondoTrasp.png';
  };

  const unit1Members = [
    {
      name: "Antonio Iacobini",
      email: 'antonio.iacobini@uniroma1.it',
      imageUrl: "https://phd.uniroma1.it/dottorati/cartellaProfili/7f6afb13-1506-4f85-afd9-a39049957d04.jpg",
      resp: true,
    },
    {
      name: "Anna Maria D’Achille",
      email: 'annamaria.dachille@uniroma1.it',
      imageUrl: 'https://phd.uniroma1.it/dottorati/cartellaProfili/4abaf0b4-de47-4f8e-a8c8-6cf013b615e9.jpg',
    },
    {
      name: "Giovanni Gasbarri",
      email: 'giovanni.gasbarri@cnr.it',
      imageUrl: 'https://www.bisanziosvelata.it/g_gasbarri.jpg',
    },
    {
      name: "Alessandra Guiglia",
      email: 'alessandra.guiglia@uniroma1.it',
      imageUrl: 'https://0.academia-photos.com/56736135/53743965/41876249/s200_alessandra.guiglia.jpg',
    },
    {
      name: "Andrea Luzzi",
      email: 'andrea.luzzi@uniroma1.it',
      imageUrl: 'https://corsidilaurea.uniroma1.it/sites/default/files/styles/user_picture/public/user_pictures/al_2020_2.jpg?itok=UfDElez8',
    },
    {
      name: "Andrea Paribeni",
      email: 'andrea.paribeni@uniurb.it',
      imageUrl: 'https://0.academia-photos.com/3028155/6558518/7415480/s200_andrea.paribeni.jpg',
    },
    {
      name: "Alessandro Taddei",
      email: 'ale.taddei@uniroma1.it',
      imageUrl: 'https://corsidilaurea.uniroma1.it/sites/default/files/pictures/picture-25998-1643975595.jpg',
    },
  ];

  const unit2Members = [
    {
      name: "Maria Luigia Fobelli",
      organization: "Università degli Studi Roma Tre",
      email: 'marialuigia.fobelli@uniroma3.it',
      imageUrl: 'https://www.bisanziosvelata.it/ml_fobelli.jpg',
      resp: true,
    },
    {
      name: "Livia Bevilacqua",
      email: 'livia.bevilacqua@uniroma3.it',
      imageUrl: 'https://0.academia-photos.com/734994/467991/21170161/s200_livia.bevilacqua.jpg',
    },
    {
      name: "Giuliana Calcani",
      organization: "Università degli Studi Roma Tre",
      email: 'giuliana.calcani@uniroma3.it',
      imageUrl: 'https://i1.rgstatic.net/ii/profile.image/287753511161866-1445617191668_Q512/Giuliana-Calcani-2.jpg',
    },
    {
      name: "Maria Caterina Ciclosi",
      organization: "Università degli Studi Roma Tre",
      email: 'caterinaciclosi@gmail.com',
      imageUrl: 'https://media.licdn.com/dms/image/C4D03AQGH-ukLPbmOaQ/profile-displayphoto-shrink_800_800/0/1517572764705?e=2147483647&v=beta&t=ATQX2gUKTLXxPLVs5TEgmsv1O8DFDMDav1zGe6rRZYU',
    },
    {
      name: "Maria Raffaella Menna",
      organization: "Università degli Studi della Tuscia",
      email: 'mrmenna@unitus.it',
    },
    {
      name: "Mario Micheli",
      organization: "Università degli Studi Roma Tre",
      email: 'mario.micheli@uniroma3.it',
      imageUrl: 'https://www.bisanziosvelata.it/m_micheli.png',
    },
    {
      name: "Silvia Ronchey",
      organization: "Università degli Studi Roma Tre",
      email: 'silvia.ronchey@uniroma3.it',
      imageUrl: 'http://asi.uniroma3.it/download/foto/1589300006_Silvia-Ronchey-photo-Leonardo-Cendamo-obbligo-copyright_crop.jpg',
    },
  ];

  const unit3Members = [
    {
      name: "Simona Moretti",
      organization: "Libera Università di Lingue e Comunicazione IULM ",
      email: 'simona.moretti@iulm.it',
      imageUrl: 'https://www.iulm.it/wps/wcm/connect/iulm/b3a2c06c-5434-4759-ad53-761ec54bfc82/Simona+MORETTI_350x350.png?MOD=AJPERES&CACHEID=ROOTWORKSPACE.Z18_N19GHC41OO5PD0QACD0HKQ38C6-b3a2c06c-5434-4759-ad53-761ec54bfc82-otgu9XU',
      resp: true,
    },
    {
      name: "Massimo Bernabò",
      organization: "Libera Università di Lingue e Comunicazione IULM ",
      email: 'massimo.bernabo@unipv.it',
      imageUrl: 'https://www.bisanziosvelata.it/m_bernabo.jpg',
    },
    {
      name: "Angela Besana",
      organization: "Libera Università di Lingue e Comunicazione IULM ",
      email: 'angela.besana@iulm.it',
      imageUrl: 'https://www.bisanziosvelata.it/a_besana.jpg',
    },
    {
      name: "Tommaso Casini",
      organization: "Libera Università di Lingue e Comunicazione IULM ",
      email: 'tommaso.casini@iulm.it',
      imageUrl: 'https://www.iulm.it/wps/wcm/connect/iulm/be001525-4a58-44cf-8191-518402e6da9e/Tommaso+CASINI_350x350.png?MOD=AJPERES&CACHEID=ROOTWORKSPACE.Z18_N19GHC41OO5PD0QACD0HKQ38C6-be001525-4a58-44cf-8191-518402e6da9e-opvbN3X',
    },
    {
      name: "Annamaria Esposito",
      organization: "Libera Università di Lingue e Comunicazione IULM ",
      email: 'annamaria.esposito@iulm.it',
      imageUrl: 'https://pbs.twimg.com/profile_images/975365225343012864/vz98Sofo_400x400.jpg',
    },
    {
      name: "Francesca Urbinati",
      organization: "Libera Università di Lingue e Comunicazione IULM ",
      email: 'francesca.urbinati.ricerca@gmail.com',
      imageUrl: 'https://www.bisanziosvelata.it/f_urbinati.jpeg',
    },
    {
      name: "Lucrezia Sozzè",
      email: 'lucreso@hotmail.it',
      imageUrl: 'https://www.bisanziosvelata.it/l_sozze.jpeg',
    },
  ];

  const unit4Members = [
    {
      name: "Manuela De Giorgi",
      organization: "Università del Salento",
      email: 'manuela.degiorgi@unisalento.it',
      imageUrl: 'https://www.unisalento.it/documents/20152/245693/foto+profilo.jpg/caa93e85-7ff7-b1b7-60e4-97a39f162309?version=1.0&t=1643730530468',
      resp: true,
    },
    {
      name: "Giulia Arcidiacono",
      organization: "Università del Salento",
      email: 'giulia.arcidiacono@unict.it',
      imageUrl: 'https://www.disum.unict.it/foto_docenti/279.jpg',
    },
    {
      name: "Irene Caracciolo",
      organization: "Università del Salento",
      email: 'caraccioloire@gmail.com',
      imageUrl: 'https://www.bisanziosvelata.it/i_caracciolo.jpg',
    },
    {
      name: "Marina Falla",
      organization: "Università del Salento",
    },
    {
      name: "Maria Teresa Giampaolo",
      organization: "Università del Salento",
      email: 'mariateresa.giampaolo@unisalento.it',
    },
    {
      name: "Claudia Guastella",
      organization: "Università del Salento",
    },
    {
      name: "Paolo Güll",
      organization: "Università del Salento",
      email: 'paolo.gull@unisalento.it',
      imageUrl: 'https://archeologiavocidalpassato.files.wordpress.com/2021/10/paolo-gull.jpg',
    },
  ];

  const otherMembers = [  
    {
      name: "Eleonora Bernasconi",
      role: "Webmaster",
    },
    {
      name: "Caterina Lubrano",
      role: "Ricerca iconografica",
    },
    // {
    //   name: "Gaia Pedriglieri",
    //   role: "Ricerca iconografica",
    // },
    {
      name: "Rebecca Louise Milner",
      role: "Traduzione e revisione testi in lingua inglese",
    },
    {
      name: "Francesco Monticini",
      role: "Revisione epigrafica",
    },
  ]

  const coordinatoreNazionale = [
    {
      name: "Antonio Iacobini",
      email: 'antonio.iacobini@uniroma1.it',
      imageUrl: "https://phd.uniroma1.it/dottorati/cartellaProfili/7f6afb13-1506-4f85-afd9-a39049957d04.jpg",
    }
  ]

  return (
    <div className="team-section">
      <h2 style={{textAlign:'center', padding:'20px'}}>
        {selectedLanguage === 'it' ? 'Coordinatore nazionale' : 'National coordinator'}
      </h2>
      <Row className='g-4 justify-content-center RowCustom mb-4'>
        {coordinatoreNazionale.map((member, idx) => (
          <Col key={idx}>
            <Card className="member-card">
              {member.imageUrl ? (
                <Card.Img variant="top" src={member.imageUrl} alt={member.name} className="member-image" />
              ) : (
                <Card.Img variant="top" src={getPlaceholderImage()} alt="Placeholder" className="member-image" />
              )}
              <Card.Body>
                <Card.Title className="member-name">{member.name}</Card.Title>
                <Card.Text className="member-email">
                  <a className="aLink" href={`mailto:${member.email}`}>E-MAIL</a>
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
      <Row className="RowCustom">
        <Col className="text-center styleRowsS" sm={4} md={5}>
            <h2 className='titleColl'>
              {selectedLanguage === 'it'
              ? 'Sapienza Università di Roma'
              : 'Sapienza University of Rome'}
            </h2>
            <Row className='g-4 justify-content-center RowCustom'>
              {unit1Members.map((member, idx) => (
                <Col key={idx}>
                  <Card className="member-card">
                    {member.imageUrl ? (
                      <Card.Img
                        variant="top"
                        src={member.imageUrl}
                        alt={member.name}
                        className="member-image"
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src={getPlaceholderImage()}
                        alt="Placeholder"
                        className="member-image"
                      />
                    )}
                    <Card.Body>
                      <Card.Title className="member-name">{member.name}</Card.Title>
                      {member.resp && (
                        <h5>
                        <Badge bg="warning">
                          {selectedLanguage === 'it' ? 'Responsabile Unità' : 'Coordinator'}
                        </Badge>
                      </h5>
                      )}
                      <Card.Text className="member-email">
                        <a className="aLink" href={`mailto:${member.email}`}>E-MAIL</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
        </Col>

        <Col className="text-center styleRowsS" sm={4} md={5}>
          <h2 className='titleColl'>
            {selectedLanguage === 'it'
              ? 'Università degli Studi Roma Tre'
              : 'Università degli Studi Roma Tre'}
          </h2>
          <Row className='g-4 justify-content-center RowCustom'>
            {unit2Members.map((member, idx) => (
              <Col key={idx}>
                <Card className="member-card">
                  {member.imageUrl ? (
                    <Card.Img
                      variant="top"
                      src={member.imageUrl}
                      alt={member.name}
                      className="member-image"
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      src={getPlaceholderImage()}
                      alt="Placeholder"
                      className="member-image"
                    />
                  )}
                  <Card.Body>
                    <Card.Title className="member-name">{member.name}</Card.Title>
                    {member.resp && (
                      <h5>
                      <Badge bg="warning">
                        {selectedLanguage === 'it' ? 'Responsabile Unità' : 'Coordinator'}
                      </Badge>
                    </h5>
                    )}
                    <Card.Text className="member-email">
                    <a className="aLink" href={`mailto:${member.email}`}>E-MAIL</a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
      <Row className="RowCustom">
        <Col className="text-center styleRowsS" sm={4} md={5}>
          <h2 className='titleColl'>
          {selectedLanguage === 'it'
            ? 'Libera Università di Lingue e Comunicazione IULM '
            : 'Libera Università di Lingue e Comunicazione IULM '}
          </h2>
          <Row className='g-4 justify-content-center RowCustom'>
            {unit3Members.map((member, idx) => (
              <Col key={idx}>
                <Card className="member-card">
                  {member.imageUrl ? (
                    <Card.Img
                      variant="top"
                      src={member.imageUrl}
                      alt={member.name}
                      className="member-image"
                    />
                  ) : (
                    <Card.Img
                      variant="top"
                      src={getPlaceholderImage()}
                      alt="Placeholder"
                      className="member-image"
                    />
                  )}
                  <Card.Body>
                    <Card.Title className="member-name">{member.name}</Card.Title>
                    {member.resp && (
                      <h5>
                      <Badge bg="warning">
                        {selectedLanguage === 'it' ? 'Responsabile Unità' : 'Coordinator'}
                      </Badge>
                    </h5>
                    )}
                    <Card.Text className="member-email">
                    <a className="aLink" href={`mailto:${member.email}`}>E-MAIL</a>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Col>
        <Col className="text-center styleRowsS" sm={4} md={5}>
            <h2 className='titleColl'>
            {selectedLanguage === 'it'
              ? 'Università del Salento'
              : 'Università del Salento'}
            </h2>
            <Row className='g-4 justify-content-center RowCustom'>
              {unit4Members.map((member, idx) => (
                <Col key={idx}>
                  <Card className="member-card">
                    {member.imageUrl ? (
                      <Card.Img
                        variant="top"
                        src={member.imageUrl}
                        alt={member.name}
                        className="member-image"
                      />
                    ) : (
                      <Card.Img
                        variant="top"
                        src={getPlaceholderImage()}
                        alt="Placeholder"
                        className="member-image"
                      />
                    )}
                    <Card.Body>
                      <Card.Title className="member-name">{member.name}</Card.Title>
                      {member.resp && (
                        <h5>
                        <Badge bg="warning">
                          {selectedLanguage === 'it' ? 'Responsabile Unità' : 'Coordinator'}
                        </Badge>
                      </h5>
                      )}
                      <Card.Text className="member-email">
                      <a className="aLink" href={`mailto:${member.email}`}>E-MAIL</a>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
        </Col>
      </Row>
      <h2 style={{textAlign:'center', padding:'20px'}} class='mt-4'>
        {selectedLanguage === 'it' ? 'Altri collaboratori' : 'Other collaborators'}
      </h2>
      <Row className='g-4 justify-content-center RowCustom'>
        {otherMembers.map((member, idx) => (
          <Col key={idx}>
            <Card className="member-card">
              <Card.Img
                variant="top"
                src={getPlaceholderImage()}
                alt="Placeholder"
                className="member-image"
              />
              <Card.Body>
                <Card.Title className="member-name">{member.name}</Card.Title>
                <Card.Text className="member-email">
                  {member.role}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </div>
  );
}

export default TeamSection;