// PaginationComponent.js
import React from 'react';
import Pagination from "../Pagination/Pagination";

const PaginationComponent = ({ currentPage, pageSize, totalArtworksCount, handlePageChange }) => {
  // console.log('currentPage', currentPage, 'pageSize', pageSize, 'totalArtworksCount', totalArtworksCount)
  return (
    <Pagination
      currentPage={currentPage}
      pageSize={pageSize}
      totalArtworksCount={totalArtworksCount}
      onPageChange={handlePageChange}
    />
  );
};

export default PaginationComponent;
