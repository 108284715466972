import React from 'react';
import Col from 'react-bootstrap/Col';
import './Conservation.scss';
import { useLanguage } from '../../../LanguageContext';

const ConservationAndRestoration = ({ currentArtwork, isArrayEmpty }) => {
  // Converti i ritorni a capo in tag <br> se necessario
  const formatText = (text) => {
    return { __html: text.replace(/\n/g, '<br>') };
  };

  const renderTextWithLineBreaks = (text) => {
    try {
      return text.split('\n').map((line, index) => (
        <React.Fragment key={index}>
          {line}
          {/* <br /> */}
        </React.Fragment>
      ));
    } catch (e) {
      console.log(e);
    }
    
  };
  const {selectedLanguage} = useLanguage();

  return (
    !isArrayEmpty(currentArtwork.stato_conservazione_restauri) &&
    currentArtwork.stato_conservazione_restauri !== false && (
      <Col className="col-12 col-md-12" style={{ textAlign: 'justify' }}>
        <label>
          <strong>{selectedLanguage === 'it' ? 'Stato di conservazione e storia dei restauri' : 'Conservation status and restoration history'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
          <span>{renderTextWithLineBreaks(currentArtwork.stato_conservazione_restauri)}</span>
        </Col>
      </Col>
    )
  );
};

export default ConservationAndRestoration;
