import React, { createContext, useState, useContext, useEffect } from 'react';

const LanguageContext = createContext();

export const useLanguage = () => useContext(LanguageContext);

export function LanguageProvider({ children }) {
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem('selectedLanguage') || 'it'
  );

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  };

  // Update the URL when selectedLanguage changes
  useEffect(() => {
    const newUrl = `${window.location.pathname}?lang=${selectedLanguage}`;
    window.history.replaceState({ path: newUrl }, '', newUrl);
  }, [selectedLanguage]);

  return (
    <LanguageContext.Provider value={{ selectedLanguage, handleLanguageChange }}>
      {children}
    </LanguageContext.Provider>
  );
}
