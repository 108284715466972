import React from 'react';
import Col from 'react-bootstrap/Col';
import MapComponent from '../../MapComponent/MapComponent';
import './LuogoConservazione.scss';
import { useLanguage } from '../../../LanguageContext';
import MapMultiPoint from '../../MapMultiPoint/MapMultiPoint';

const LuogoConservazione = ({ isArrayEmpty, currentArtwork, isDetail }) => {
  const {selectedLanguage} = useLanguage();
  return (
    <React.Fragment>
      
      {!isArrayEmpty(currentArtwork.location) && (
        <Col className="col-12 col-md-12">
          <label>
            <strong>{selectedLanguage === 'it' ? 'Luogo di conservazione' : 'Place'}</strong>
          </label>
          <Col className="col-12 col-md-12 boxLuogo">
            <p>{currentArtwork.location.city_town}, {currentArtwork.location.name_off}</p>
            
            {!isDetail && currentArtwork.location.latitude !== 0 && currentArtwork.location.longitude !== 0 && (
              <MapComponent
                name={currentArtwork.location.name_off}
                lat={currentArtwork.location.latitude}
                lng={currentArtwork.location.longitude}
              />
            
            )}
          </Col>
        </Col>
      )}

      {currentArtwork.inventory_number && (
        <Col className="col-12 col-md-12">
          <label>
            <strong>{selectedLanguage === 'it' ? 'N° Inventario ' : 'Inventory No. '}</strong>
          </label>
          <div className='mt-3'>
            {currentArtwork.inventory_number}
          </div>
        </Col>
      )}
    </React.Fragment>
  );
};

export default LuogoConservazione;
