import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import './footer.css';
import fb from './fb.png';
import yutu from './yutu.png';
import { useLanguage } from '../../LanguageContext';


const Footer = () => {
    const { selectedLanguage, handleLanguageChange } = useLanguage();
  
    return (
        <footer className="custumfooter">
            <Row className="justify-content-md-center">
                <Col md={12}>
                <p>© {new Date().getFullYear()} bisanziosvelata.it</p>
                </Col>
            </Row>

        
            <Row className="justify-content-md-center" style={{placeItems:"center"}}>
                <Col md={3} style={{alignSelf:"center"}}>
                    <Row className="justify-content-md-center">
                        <Col>
                        <a className='linkUnivB' href='/cookie-policy'>{selectedLanguage === 'it' ? 'Cookie Policy' : 'Cookie Policy'}</a>
                        </Col>
                        <Col>
                        <a className='linkUnivB' href='/credits'>{selectedLanguage === 'it' ? 'Crediti' : 'Credits'}</a>
                        </Col>
                    </Row>
                </Col>
                <Col md={7} style={{alignSelf:"center"}}>
                    <Row style={{alignItems:"center"}}>
                        <Col>
                            <a className = "linkUnivB" href="https://www.uniroma1.it/" target="_blank" rel="noopener noreferrer">
                            <Image className="imageUniSmall2" src="https://www.bisanziosvelata.it/01_uniroma1.png"  />
                            </a>
                        </Col>
                        <Col className="custumCol">
                            <a className = "linkUnivB" href="https://www.uniroma3.it/" target="_blank" rel="noopener noreferrer">
                            <Image className="imageUniSmall2" src="https://www.bisanziosvelata.it/uniroma3.png"  />
                            </a>
                        </Col>
                        <Col className="custumCol">
                            <a className = "linkUnivB" href="https://www.iulm.it/en" target="_blank" rel="noopener noreferrer">
                            <Image className="imageUniSmall2" src="https://www.bisanziosvelata.it/iulm.png"  />
                            </a>
                        </Col>
                        <Col className="custumCol">
                            <a className = "linkUnivB" href="https://www.unisalento.it/" target="_blank" rel="noopener noreferrer">
                            <Image className="imageUniSmall2" src="https://www.bisanziosvelata.it/04_unisa.png"  />
                            </a>
                        </Col>
                    </Row>
                </Col>
                <Col md={2}>
                    <Row>
                        <Col>
                            <a className="linkUnivB" href="https://www.youtube.com/" target="_blank" rel="noopener noreferrer">
                                <Image className="imageUniSmallfoot" src={yutu} roundedCircle />
                            </a>
                        </Col>
                        <Col>
                            <a className="linkUnivB" href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
                                <Image className="imageUniSmallfoot" src={fb} roundedCircle />
                            </a>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </footer>
    );
};

export default Footer;
