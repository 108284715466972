import React from 'react';
import { Row, Col } from 'react-bootstrap';
import NameFilter from './NameFilter'; 
import DateFilter from './DateFilter'; 
import CategoriesFilter from './CategoriesFilter';
import SubjectFilter from './SubjectFilter'; 
import { useLanguage } from '../../LanguageContext';
import LuogoConservazioneFilter from './LuogoConservazioneFilter';
import FreeSearchFilter from './FreeSearchFilter';

const FilterMain = ({ 
    searchName,
    onChangeSearchName,
    searchData_fine,
    searchData_inizio,
    onChangeSearchDataInizio,
    onChangeSearchDataFine,
    retrieveArtworks,
    tags,
    subjectTags,
    selectedTag,
    selectedSubjectTag,
    onChangeSelectedTag,
    onChangeSelectedSubjectTag,
    search_luogo_conservazione,
    onChangeLuogoConservazione,
    freeSearch,
    onChangeFreeSearch
    }) => {
    
    const { selectedLanguage } = useLanguage();
    return (
        <Row className="text-left">
            <Col md={4} className="text-left">
                <FreeSearchFilter
                    freeSearch={freeSearch}
                    retrieveArtworks={retrieveArtworks}
                    onChangeFreeSearch={onChangeFreeSearch}
                    onChangeSearchName={onChangeSearchName}
                    onChangeSearchDataInizio={onChangeSearchDataInizio}
                    onChangeSearchDataFine={onChangeSearchDataFine}
                    onChangeSelectedTag={onChangeSelectedTag}
                    onChangeSelectedSubjectTag={onChangeSelectedSubjectTag}
                    onChangeLuogoConservazione={onChangeLuogoConservazione}
                />
            </Col>
            <Col md={4} className="text-left">
                <NameFilter
                    searchName={searchName}
                    onChangeSearchName={onChangeSearchName}
                    retrieveArtworks={retrieveArtworks}
                />
            </Col>
            <Col md={4} className="text-left">
                <DateFilter
                    searchData_fine={searchData_fine}
                    searchData_inizio={searchData_inizio}
                    onChangeSearchDataInizio={onChangeSearchDataInizio} 
                    onChangeSearchDataFine={onChangeSearchDataFine}
                    retrieveArtworks={retrieveArtworks}
                />
            </Col>
            <Col md={4} className="text-left">
                <CategoriesFilter
                    tags={tags}
                    selectedTag={selectedTag}
                    retrieveArtworks={retrieveArtworks}
                    onChangeSelectedTag={onChangeSelectedTag}
                />
            </Col>
            {/* <Col md={4} className="text-left">
                <SubjectFilter
                    subjectTags={subjectTags}
                    selectedSubjectTag={selectedSubjectTag}
                    retrieveArtworks={retrieveArtworks}
                    onChangeSelectedSubjectTag={onChangeSelectedSubjectTag}
                />
            </Col> */}
            <Col md={4} className="text-left">
                <LuogoConservazioneFilter
                    search_luogo_conservazione={search_luogo_conservazione}
                    retrieveArtworks={retrieveArtworks}
                    onChangeLuogoConservazione={onChangeLuogoConservazione}
                />
            </Col>

            <Col md={4} className="text-left">
                <button
                    className="btn btn-outline-secondary mb-3"
                    type="button"
                    onClick={() => {
                        onChangeSearchName({ target: { value: '' } });
                        onChangeSearchDataInizio({ target: { value: '' } });
                        onChangeSearchDataFine({ target: { value: '' } });
                        onChangeSelectedTag({ target: { value: '' } });
                        onChangeSelectedSubjectTag({ target: { value: '' } });
                        onChangeLuogoConservazione({ target: { value: '' } });
                        onChangeFreeSearch({ target: { value: '' } });
                        retrieveArtworks();
                    }}
                >
                    {selectedLanguage === 'it' ? 'Azzera filtri' : 'Reset filters'}
                </button>
            </Col>
        </Row>
    );
};

export default FilterMain;
