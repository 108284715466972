// ArtworkList.js
import React from 'react';
import Col from 'react-bootstrap/Col';
import './artwork.scss';
import Loading from '../Loading/Loading';
import MapMultiPoint from '../MapMultiPoint/MapMultiPoint';

const ArtworkListMap = ({ artworks, currentIndex, setActiveArtwork, setActiveArtworksList, loading }) => {
  return (
    <Col className="text-left custumListContMap mb-5">
      {loading ? 
        <Loading />
      : 
        <MapMultiPoint 
          points={artworks} 
          setActiveArtworksList={setActiveArtworksList}
          setActiveArtwork={setActiveArtwork} 
          currentIndex={currentIndex}
        />
      }
    </Col>
  );
};
export default ArtworkListMap;
