import React from 'react';
import Col from 'react-bootstrap/Col';
import './DatiTecMat.scss';
import { useLanguage } from '../../../LanguageContext';

const TechnicalMaterials = ({ currentArtwork, isArrayEmpty }) => {
  const {selectedLanguage} = useLanguage();
  // Helper function to get the correct language value
  const getLangValue = (data, lang) => {
    return lang === 'it' ? data['it_IT'] : data['en_GB'];
  };

  return (
    !isArrayEmpty(currentArtwork.material_and_technique_ids) && (
      <Col className="col-12 col-md-12">
        <label>
          <strong>{selectedLanguage === 'it' ? 'Dati tecnico-materici' : 'Materials'}</strong>
        </label>
        <Col className="col-12 col-md-12 boxField">
          {currentArtwork.material_and_technique_ids.map((material, index) => (
            <p className='elementsMat' key={index}>
              {getLangValue(material.name, selectedLanguage)}{' '}
              {
                material.description && getLangValue(material.description, selectedLanguage) !== false && getLangValue(material.description, selectedLanguage) !== '' 
                ? ` (${getLangValue(material.description, selectedLanguage).replace('[','').replace(']','')})` 
                : null
              }
            </p>
          ))}
        </Col>
      </Col>
    )
  );
};

export default TechnicalMaterials;
