import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import './DatiIconografici.scss';
import { useLanguage } from '../../../LanguageContext';

const DatiIconografici = ({ currentArtwork, isArrayEmpty }) => {
    const { selectedLanguage } = useLanguage();
    const getLangValue = (data) => {
        return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
      };
    
    return (
    !isArrayEmpty(currentArtwork.dati_iconografici_ids) && (
        <Col className="col-12 col-md-12">
            <label>
                <strong>{selectedLanguage === 'it' ? 'Dati iconografici' : 'Iconographic data'}</strong>
            </label>

            <Col className="col-12 col-md-12 boxField">
                {currentArtwork.dati_iconografici_ids.map((element, index) => (
                <p className='elementsMat' key={index}>
                    {element.soggetto ? <span key={index}>
                            {element.soggetto}
                        </span> : null}
                </p>
                ))}
            </Col>

        </Col>
    )
  );
};

export default DatiIconografici;
