import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";
import ArtworkDataService from "../../services/ArtworkService";
import Loading from "../../components/Loading/Loading";
import ErrorLoading from "../../components/Error/Error";
import ArtworkContent from "../../components/Artwork/Artwork";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './artworkpage.scss';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../LanguageContext';

const ArtworkPage = () => {
  const { id } = useParams();
  // console.log("id",id);

  const initialArtworkState = {
    id: null,
    name: {},
    short_description: {},
    location: {
      name: "",
      country: "",
      region: "",
      latitude: 0,
      longitude: 0,
    },
    inscription_ids: [],
    pictures: [],
    dates: [],
  };

  const [currentArtwork, setCurrentArtwork] = useState(initialArtworkState);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const getArtwork = async () => {
      try {
        setLoading(true);
        setError("");

        const response = await ArtworkDataService.get(id);
        
        // console.log(response);
        const artworkData = response.response;

        if (artworkData) {
          setCurrentArtwork(artworkData);

        } else {
          setError("Artwork not found.");
        }
      } catch (error) {
        console.error("Error fetching artwork data:", error);
        setError("Error fetching artwork data.");
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      getArtwork();
    }
  }, [id]);

  const { selectedLanguage } = useLanguage();
  const getLangValue = (data) => {
    return data ? data[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  };

  return (
    <>
      <Helmet>
        <title>{getLangValue(currentArtwork.name)}</title>
        <meta
          name="description"
          content={getLangValue(currentArtwork.short_description)}
        />
      </Helmet>
        <Container className="bg-container">
          <Col className="mt-5 mb-5">
            {loading ? (
              <Loading />
            ) : error ? (
              <ErrorLoading />
            ) : currentArtwork ? (
              <ArtworkContent currentArtwork={currentArtwork}/>
            ) : (
              null
            )}
          </Col>
        </Container>

      <Footer />
    </>
  );
};

export default ArtworkPage;
