// ArtworkListItem.js
import React from 'react';
import { useLanguage } from '../../LanguageContext';
import './artwork.scss';

const ArtworkListItem = ({ artwork, isActive, handleClick }) => {
  // console.log(artwork)
  const handleClickEvent = () => {
    handleClick();
  };

  const getLangContent = (content) => content ? content[selectedLanguage === 'it' ? 'it_IT' : 'en_GB'] : '';
  const { selectedLanguage, handleLanguageChange } = useLanguage();

  // console.log(isActive);
  return (
    <li
      className={`list-group-item itemArtwork ${isActive ? 'active' : ''}`}
      onClick={handleClickEvent}
    >
      <div style={{fontSize:'12px', fontStyle: 'italic'}}>
      {artwork.location.city_town !== false ? `${artwork.location.city_town}, ${artwork.location.name_off}` : 
      `${artwork.location.name_off}`}
      </div>
      <div style={{fontWeight:'400'}}>
        {getLangContent(artwork.name)}
      </div>
    </li>
  );
};

export default ArtworkListItem;
