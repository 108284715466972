// NameFilter.js
import React from 'react';
import { useLanguage } from '../../LanguageContext';

const DateFilter = ({ searchData_fine, searchData_inizio, onChangeSearchDataInizio, onChangeSearchDataFine, retrieveArtworks}) => {
  const {selectedLanguage} = useLanguage();
  return (
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control"
            placeholder={selectedLanguage === 'en' ? 'From (start date)' : 'Da (data iniziale)'}
            value={searchData_inizio}
            onChange={onChangeSearchDataInizio}
          />
            <input
                type="text"
                className="form-control"
                placeholder={selectedLanguage === 'en' ? 'To (end date)' : 'A (data finale)'}
                value={searchData_fine}
                onChange={onChangeSearchDataFine}
            />

          <div className="input-group-append">
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={() => retrieveArtworks()}
            >
              {selectedLanguage === 'it' ? 'Cerca' : 'Search'}
            </button>
          </div>
        </div>

  );
};

export default DateFilter;
