import React from 'react';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './staffpage.scss';

import CarouselPages from '../../components/CarouselPages/CarouselPages';
import { useLanguage } from '../../LanguageContext';
import TeamSection from '../../components/Team/TeamSection';
import Footer from '../../components/Footer/Footer';
import { Helmet } from 'react-helmet';
import { Container } from 'react-bootstrap';
function StaffPage() {
  const { selectedLanguage } = useLanguage();

  return (
    <>
    <Helmet>
      {/* Titolo */}
      <title>Bisanzio Svelata - Staff</title>

      {/* Meta Tags */}
      <meta name="description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta name="keywords" content="Bisanzio, Bisanzio Svelata, progetto di ricerca, storia, misteri, Impero Bizantino" />

      {/* Open Graph / Facebook */}
      <meta property="og:title" content="Bisanzio Svelata - Staff" />
      <meta property="og:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta property="og:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="og:url" content="https://www.bisanziosvelata.it/staff" />
      <meta property="og:type" content="website" />

      {/* Twitter */}
      <meta property="twitter:title" content="Bisanzio Svelata - Staff" />
      <meta property="twitter:description" content="Esplora i segreti di Bisanzio con Bisanzio Svelata - un progetto di ricerca dedicato a svelare i misteri dell'Impero Bizantino." />
      <meta property="twitter:image" content="https://www.bisanziosvelata.it/bisanziosvelata.png" />
      <meta property="twitter:url" content="https://www.bisanziosvelata.it/staff" />

      {/* URL Canonico */}
      <link rel="canonical" href="https://www.bisanziosvelata.it/staff" />
    </Helmet>
    
    <Container className="bg-container">
      <Row className="justify-content-center align-items-center">
          <TeamSection />
      </Row>
    </Container>
    <Footer />
    </>
  );
}

export default StaffPage;
