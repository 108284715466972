import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './privacypolicypage.scss';
import { useLanguage } from '../../LanguageContext';
import Footer from '../../components/Footer/Footer';

function PrivacyPolicyPage() {
  const { selectedLanguage } = useLanguage();

  const primaColonna = 12

  return (
    <>    
    <Container fluid className="bg-container">
      <Row className="justify-content-center align-items-center rowTitle">
      <Col md={12} sx={12} lg={12} className='homeScrittaHOME'>
            
            <Col md={primaColonna} sx={primaColonna} lg={primaColonna}>
            
              <h1 className="titleO" style={{fontSize:"25px"}}>
                {selectedLanguage === 'it' ? 'Privacy Policy' : 'Privacy Policy' }
              </h1>
              
            </Col>
           
          </Col>

      </Row>
      <Footer />
    </Container>
    
    </>
  );
}

export default PrivacyPolicyPage;
